import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../Components/Loading";
import DashboardLoginNavbar from "../../Components/DashboardLoginNavbar";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { setDivision } from "../../Redux/Action/division";

function ListofOrdersReady() {
  const [isloading, setisloading] = useState(false);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [listOfOrders, setlistOfOrders] = useState([]);
  const userState = useSelector((state) => state?.changeTheState?.user);
  const loggedInUser = userState?.emailId;
  const dispatch = useDispatch();
  const [userdivisionRights, setuserDivisionList] = useState([]);
  const divisionState = useSelector((state) => state.changeDivision);
  const [selDivision, setSelDivision] = useState("");

  const getOrderStatus = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/getOrderStatus?DIVISION=${divisionState}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        const filteredOrders = response.data.filter(
          (order) =>
            order.PICKEDUP !== true &&
            (order.PREPERATIONSTATUS === 2 || order.PREPERATIONSTATUS === 1)
        );
        setlistOfOrders(filteredOrders);
        console.log(filteredOrders);
        setisloading(false);
      })

      .catch((error) => {
        console.error(
          "Error fetching List Of Order Status /getOrderStatus:",
          error
        );
      });
  };

  useEffect(() => {
    axios
      .get(`${BASEURL}/getdivisionRights?USEREMAIL=${loggedInUser}`)
      .then((response) => {
        console.log(response.data);
        setuserDivisionList(response?.data);
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
      });
    getOrderStatus();
  }, []);

  useEffect(() => {
    getOrderStatus();
  }, [selDivision]);

  useEffect(() => {
    const socket = io("https://marstrackbackend.azurewebsites.net"); // Establish WebSocket connection

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("orderInserted", (data) => {
      console.log("Received new order event:", data);

      // getOrderStatus();
    });

    // Clean up WebSocket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <DashboardLoginNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3 underline">
        <h1 className="header__of__page">ORDER STATUS</h1>
      </header>
      <div className="flex justify-center font-bold text-[15px] mt-2">
        <p>Division:</p>
        <select
          onChange={(e) => {
            setSelDivision(e.target.value);
            dispatch(setDivision(e.target.value));
          }}
          className="lg:w-[300px] w-[250px]  border-black border ml-2 mr-2 rounded"
        >
          <option>{divisionState}</option>
          {userdivisionRights.map((item) =>
            item.OM_USER_DIVISION !== divisionState ? (
              <option key={item.OM_USER_DIVISION} value={item.OM_USER_DIVISION}>
                {item.OM_USER_DIVISION}
              </option>
            ) : null
          )}
        </select>
      </div>

      <div className="lg:flex justify-center w-full items-start lg:overflow-none overflow-auto mb-[300px] min-h-[500px]">
        <table className="table_1 " style={{ fontSize: "11px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td className="text-right">ORDER NO</td>
              <td>ORDER DATE</td>
              <td>CUSTOMER NAME</td>
              <td>STATUS</td>
              <td>TIME TAKEN</td>
            </tr>
          </thead>
          <tbody>
            {listOfOrders.length > 0 ? (
              listOfOrders.map((item, index) => (
                <tr key={index} style={{ textTransform: "uppercase" }}>
                  <td className="text-right">{item.ENQUIRYNO}</td>
                  <td>
                    {item.ENQUIRYDATE
                      ? item?.ENQUIRYDATE.slice(8, 10) +
                        "/" +
                        item?.ENQUIRYDATE.slice(5, 7) +
                        "/" +
                        item?.ENQUIRYDATE.slice(0, 4)
                      : "NA"}
                  </td>
                  <td>{item["CLIENT NAME"] ? item["CLIENT NAME"] : "N/A"}</td>
                  <td>
                    {item.PREPERATIONSTATUS === 1 ? "STARTED" : "COMPLETED"}
                  </td>
                  <td>
                    {(() => {
                      const currentDate = new Date(); // Get current date and time
                      let startDate;

                      if (item.PREPERATIONSTATUS === 2) {
                        startDate = new Date(item.PREPERATIONSTARTDATETIME); // Use PREPERATIONSTARTDATETIME if status is 2
                      } else {
                        startDate = currentDate; // Otherwise, compare to current date
                      }

                      const enquiryDate = new Date(item.ENQUIRYDATE);
                      const timeDifference = startDate - enquiryDate; // time difference in milliseconds

                      // Convert timeDifference to days, hours, and minutes
                      const totalDays = Math.floor(
                        timeDifference / (1000 * 60 * 60 * 24)
                      );
                      const totalHours = Math.floor(
                        (timeDifference % (1000 * 60 * 60 * 24)) /
                          (1000 * 60 * 60)
                      );
                      const totalMinutes = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                      );

                      return `${totalDays} days, ${totalHours} hours, ${totalMinutes} minutes`;
                    })()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  No Orders Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListofOrdersReady;
