import React, { useEffect, useState, useContext } from "react";
import "../CSS/MainLogin.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn, logOut } from "../Redux/Action/index";
import Axios from "axios";
import { legacy_createStore } from "redux";
import { logInStatus } from "../Redux/Action/loginstatus";
import LoadingSpinner from "../Components/Loading";
import Select from "react-select";
import LoginImg from "../Images/logoPOS.png";
import Footer from "../Components/Footer";
import { setDivision } from "../Redux/Action/division";
// import Navbar from "../../Components/Navbar/ClientNavbarLogin";
import { AuthContext } from "../context/AuthContext";

const MainLogin = () => {
  const { setAuthUser } = useContext(AuthContext);

  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [datas, setData] = useState([]);
  const [errData, setErrData] = useState("");
  const [userType, setUserType] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const myState = useSelector((state) => state.changeTheState);
  const [servererror, setserverError] = useState("");
  const loginState = useSelector((state) => state.changeLoginState);
  let serverResponse = true;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const [userdivisionRights, setuserDivisionList] = useState([]);
  const [selDivision, setSelDivision] = useState("");
  const [modeOfLogin, setModeOfLogin] = useState("");
  const [modeOfLoginValue, setModeOfLoginValue] = useState("");
  function clearState() {
    dispatch(logIn([]));

    dispatch(logInStatus("FALSE"));
  }
  useEffect(() => {
    clearState();
  }, []);

  useEffect(() => {
    Axios.get(`${BASEURL}/getdivisionRights`)
      .then((response) => {
        setuserDivisionList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleClick() {
    setIsLoading(true);
    if (modeOfLoginValue === "") {
      alert("Please select mode Of Login ");

      return;
    }
    if (serverResponse) {
      if (!/\S+@\S+\.\S+/.test(email)) {
        alert("Invalid email format");
        setIsLoading(false);
        return;
      }

      console.log(modeOfLoginValue);
      console.log(modeOfLogin);
      try {
        Axios.post(`${BASEURL}/login`, {
          email: email,
          password: password,
          DIVISION: selDivision,
          MODULE: modeOfLoginValue,
        })
          .then((response) => {
            console.log(response.data);
            try {
              if (response.data.message) {
                setIsLoading(false);
                setserverError(response.data.message);
                dispatch(logIn([])); // Clear previous login data
                dispatch(logInStatus("FALSE"));
              } else {
                dispatch(logIn(response.data));
                dispatch(logInStatus("TRUE"));
                dispatch(setDivision(selDivision));
                window.sessionStorage.setItem(
                  "auth-token",
                  response.data.authToken
                );
                window.sessionStorage.setItem(
                  "session-token",
                  response.data.sclientSecret
                );
                setAuthUser(response.data.authToken);
                if (modeOfLoginValue === "POS ORDER TAKER") {
                  navigate("/ORDERTAKERDASHBOARD");
                }
                if (modeOfLoginValue === "POS") {
                  navigate("/HomePage");
                }
                if (modeOfLoginValue === "KITCHEN") {
                  navigate("/KitchenDashboard");
                }
                if (modeOfLoginValue === "DASHBOARD LOGIN") {
                  navigate("/ListofOrdersReady");
                }
                setIsLoading(false);
              }
            } catch {
              if (response.status === 501) {
                setserverError("Internal server error");
              } else if (response.status === 401) {
                setserverError("Wrong username or password");
              }
              setIsLoading(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                setserverError("Wrong username or password");
              } else if (error.response.status === 501) {
                setserverError("Internal server error");
              }
              setIsLoading(false);
            } else {
              setserverError(
                "No response from server or unmatched email or password"
              );
              setIsLoading(false);
            }
          });
      } catch {
        setserverError(
          "No response from server or unmatched email or password"
        );
        setIsLoading(false);
      }
    } else {
      setserverError("Server is down. Please try again later.");
      setIsLoading(false);
    }
  }
  const [emailFormatError, setEmailFormatError] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const renderList = (
    <div>
      {/* <Navbar /> */}
      <div className="for__amin__login__size">
        {/* company name  */}

        {/* login page heading  */}

        <div className="header">
          <picture className="pic__name">
            <p className="company__name__top text-[25px]">MarsTrack POS</p>
            <img
              className="image__style"
              src={LoginImg}
              alt=""
              width="500"
              height="300"
            />
            <h1 className="company__name__bottom">Marstrack POS</h1>
          </picture>
          {/* form for admin login starts here  */}
          <div className="form">
            <main className="mainpage">
              <p
                style={
                  servererror === "Server is connected"
                    ? {
                        color: "green",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : {
                        color: "red",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }
              >
                {servererror}
              </p>
              <h2 className="headeruselogin text-[20px]">Login Page</h2>{" "}
              <div className="form_group">
                {/* error messege when user enters wrong data  */}
                <p className="loginerror">
                  <span className="skew-shake-x">{errData}</span>
                </p>
                <label for="Division">Login As</label>
                <Select
                  className=""
                  placeholder="Select mode of login..."
                  value={
                    modeOfLogin
                      ? {
                          label: modeOfLogin,
                          value: modeOfLogin,
                        }
                      : {
                          label: "Select mode of login...",
                          value: "",
                        }
                  }
                  onChange={(e) => {
                    setModeOfLogin(e.label);
                    setModeOfLoginValue(e.value);
                  }}
                  options={[
                    { label: "Select mode of login...", value: "" },
                    { label: "POS ORDER TAKER", value: "POS ORDER TAKER" },
                    { label: "KITCHEN", value: "KITCHEN" },
                    { label: "DASHBOARD LOGIN", value: "DASHBOARD LOGIN" },
                    { label: "POS", value: "POS" },
                  ]}
                />
                <p className="loginerror">
                  <span className="skew-shake-x">{errData}</span>
                </p>
                <label for="Division">Division</label>
                <Select
                  name="DIVISION"
                  onChange={(e) => {
                    setSelDivision(e.value);
                  }}
                  options={userdivisionRights.map((item) => {
                    return {
                      label: item?.OM_USER_DIVISION,
                      value: item?.OM_USER_DIVISION,
                    };
                  })}
                />
                {/* email address is taken here as input  */}

                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className={`form_control input ${
                    emailFormatError ? "invalid-email" : ""
                  }`}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailFormatError(false); // Clear email format error on input change
                  }}
                />

                {/* a secure messege for user so that they can enter their e-mail securely */}
                <small id="emailHelp" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form_group">
                {/* password is taken here as input  */}
                <label for="exampleInputPassword1">Password</label>
                <input
                  type={passwordShown ? "" : "password"}
                  className="form_control input"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <aside className="aside__forgot__and__Show">
                  {/* Forgot Password option is given so that the user can reset the password  */}
                  <div class="">
                    <Link to="/ForgotPwd">
                      <a class="forgotpass">forgot password</a>
                    </Link>
                  </div>
                  <div className="checkbox__show__password">
                    <p className="show_p_para"> Show Password</p>
                    <input
                      className="checkboxninput"
                      type="checkbox"
                      onClick={togglePassword}
                      checked={passwordShown === true ? "checked" : ""}
                    />
                  </div>
                </aside>
              </div>
              {/* login button if clicked it will redirect driver to dashbord page */}
              <div className="loginpagebtn">
                <button
                  disabled={serverResponse === true ? false : true}
                  onClick={handleClick}
                  type="submit"
                  className="submitbutton1"
                >
                  Login
                </button>
              </div>
            </main>
          </div>
        </div>
      </div>
      <div className="footer__login__page">
        <Footer />
      </div>
    </div>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};

export default MainLogin;
