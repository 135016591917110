import React, { useState } from "react";

import "./Navbar.css";

import { FaBars, FaTimes } from "react-icons/fa";
// import "../src/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { FaStepBackward } from "react-icons/fa";
const DashBoardNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="centernav sticky top-0 z-[1000] bg-white">
      <div className="navbar-container_1">
        <nav className="navbar block items-center">
          <div className="block ">
            <div className="fabar" onClick={toggleMenu}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
              {/* <FaBars /> */}
            </div>
          </div>
          <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
            <div className="hover-dropdown uppercase">
              {/* <li onClick={() => navigate("/BookingReference")}>
                Item creation
              </li>{" "}
              <li onClick={() => navigate("/InternalTransfer")}>
                Location creation
              </li> */}
              {/* <li onClick={() => navigate("/SafariPlanning")}>
                Safari Planning
              </li>
              <li onClick={() => navigate("/FuelVoucher")}>Fuel Voucher</li>
              <li onClick={() => navigate("/VehicleMovement")}>
                Vehicle Movement
              </li>
              <li onClick={() => navigate("/DriverAllocation")}>
                Driver Allocation
              </li> */}
              <li onClick={() => navigate("/ItemCreation")}>Item creation</li>
              <li onClick={() => navigate("/LocationCreation")}>
                Location creation
              </li>
              <li onClick={() => navigate("/SupplierCreation")}>
                Supplier creation
              </li>
              <li onClick={() => navigate("/CustomerMaster")}>
                Customer Master
              </li>
              <li onClick={() => navigate("/POProcessing")}>PO Processing</li>
              <li onClick={() => navigate("/GrnProcessing")}>GRN Processing</li>
            </div>
          </ul>
        </nav>
        <p className="text-2xl italic font-bold mb-1 ml-[20px]">
          Mars Track POS
        </p>
        {/* <button
          className="button__logout__navbar  font-bold"
          onClick={() => navigate("/HomePage")}
        >
          <span className="text-xl">DashBoard</span>
        </button> */}
      </div>
    </nav>
  );
};

export default DashBoardNavbar;
