export const poToGrn = (data) => {
  return {
    type: "POTOGRN",
    data: data,
  };
};

export const resetPoToGrn = () => ({
  type: "RESET_POTOGRN",
});
