import { React, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "../Components/Loading";

const Receipt = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const userState = useSelector((state) => state.changeTheState.user);
  const divisionState = useSelector((state) => state.changeDivision);
  const [division, setDivision] = useState(divisionState);
  const [date, setDate] = useState(new Date().toISOString());
  const [receiptdata, setreceiptdata] = useState([]);
  const [listofpaymentmodes, setlistofpaymentmodes] = useState([]);
  const [selpaymentmode, setselpaymentmode] = useState("");
  const [newReceiptNo, setnewReceiptNo] = useState("");
  const [pendingtopay, setpendingtopay] = useState(null);
  const location = useLocation();
  const invoiceno = location.state ? location.state.cashsaleno : "";
  const totalamount = location.state ? location.state.total : "";
  const totalvat = location.state ? location.state.vat : "";
  const totallevy = location.state ? location.state.levy : "";
  const [alreadypaidamt, setalreadypaidamt] = useState("");
  const [cashsaledata, setcashsaledata] = useState([]);
  const [cashsalepopup, setcashsalepopup] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [narration, setnarration] = useState("");
  const [receivedamt, setreceivedamt] = useState("");
  const [changegiven, setchangegiven] = useState("");
  const [search, setSearch] = useState("");
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );

  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [historic, sethistoric] = useState([]);
  const [hinvoice, sethinvoice] = useState("");
  const [htotal, sethtotal] = useState("");
  const [historicreceipts, sethistoricreceipts] = useState(false);
  const [ShowTable, setShowTable] = useState(location.state ? true : false);
  const [htillno, sethtillno] = useState("");
  const [tillno, settillno] = useState("");
  const [hvat, sethvat] = useState("");
  const [hlevy, sethlevy] = useState("");
  const [charge, setCharge] = useState("");
  const [vatperc, setvatperc] = useState(location.state ? 16 : null);
  const [levyperc, setlevyperc] = useState(location.state ? 2 : null);
  const [firstReceiptNo, setFirstReceiptNo] = useState("");

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filtHistoric = receiptdata.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.RECEIPTDATE;
    const eDate = item.RECEIPTDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "RECEIPTNO",
        "CASHSALENO",
        "PAYMODE",
        "DIVISION",
        "RECEIPTAMOUNT",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  useEffect(() => {
    getNewReceiptNo();
    getPaymentModeDetails();
    getAlreadyPaidAmount();
    getCashSaleHistoryDetails();
    getAllReceiptsDetails();
  }, []);

  const getNewReceiptNo = () => {
    axios
      .get(`${BASEURL}/getNewReceiptNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setnewReceiptNo(response.data[0].RECIEPTNO);
      })
      .catch((error) => {
        console.error("Error fetching New Receipt No:", error);
      });
  };

  const getPaymentModeDetails = () => {
    axios
      .get(`${BASEURL}/getPaymentModes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofpaymentmodes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Receipts Data:", error);
      });
  };

  const getAllReceiptsDetails = () => {
    axios
      .get(`${BASEURL}/getAllReceiptsReceipt`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setreceiptdata(response.data);
        setFirstReceiptNo(response.data[0].RECEIPTNO);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Receipts Data:", error);
      });
  };

  const getAlreadyPaidAmount = () => {
    axios
      .post(
        `${BASEURL}/getAlreadyPaidAmount`,
        {
          cashsaleno: invoiceno,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setalreadypaidamt(response.data[0].ALREADYPAIDAMT);
        if (response.data[0].ALREADYPAIDAMT > 0) {
          setpendingtopay(
            htotal
              ? Number(htotal) - Number(response.data[0].ALREADYPAIDAMT)
              : totalamount
              ? Number(totalamount) - Number(response.data[0].ALREADYPAIDAMT)
              : ""
          );
        } else {
          setpendingtopay(
            htotal
              ? Number(htotal).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : totalamount
              ? Number(totalamount).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching Already Paid Amount Data:", error);
      });
  };

  const getCashSaleHistoryDetails = () => {
    axios
      .post(
        `${BASEURL}/getCashSaleHistoryDetails`,
        {
          invoiceno: invoiceno,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setcashsaledata(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Cash Sale Data:", error);
      });
  };

  const fetchAllDetails = async (ReceiptNo) => {
    let cashsale = 0;
    setShowTable(true);
    sethistoricreceipts(true);
    await axios
      .post(
        `${BASEURL}/getReceiptDetails`,
        {
          receiptno: ReceiptNo,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setDivision(response.data[0].DIVISION);
        setnewReceiptNo(response.data[0].RECEIPTNO);
        sethinvoice(response.data[0].CASHSALENO);
        cashsale = response.data[0].CASHSALENO;
        setDate(response.data[0].RECEIPTDATE);
        sethtotal(response.data[0].RECEIPTAMOUNT);
        setnarration(response.data[0].RECEIPTNARRATION);
        setselpaymentmode(response.data[0].PAYMODE);
        setreceivedamt(response.data[0].MONEYGIVEN);
        setchangegiven(response.data[0].CHANGEGIVEN);
        setvatperc(16);
        setlevyperc(2);
      })
      .catch((error) => {
        console.error("Error fetching List Of Receipts:", error);
      });

    await axios
      .post(
        `${BASEURL}/getCashSaleHistoryDetails`,
        {
          invoiceno: cashsale,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        sethistoric(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Cash Sale History Details:", error);
      });

    await axios
      .post(
        `${BASEURL}/getCashSaleDetailsInReceipt`,
        {
          invoice: cashsale,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        sethtillno(response.data[0].TILLNO);
        sethvat(response.data[0]["VAT AMT"]);
        sethlevy(response.data[0].LEVYAMOUNT);
        setCharge(response.data[0]["AMOUNT INCLUSIVE VAT"]);
      })
      .catch((error) => {
        console.error(
          "Error fetching Cash Sale History Details Receipts:",
          error
        );
      });

    await axios
      .post(
        `${BASEURL}/getAlreadyPaidAmount`,
        {
          cashsaleno: cashsale,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setalreadypaidamt(response.data[0].ALREADYPAIDAMT);
      })
      .catch((error) => {
        console.error("Error fetching Amount Paid Details:", error);
      });
  };

  const handleButtonClick = async (operation) => {
    try {
      const latestReceiptNo = await getLatestReceiptNo();

      if (operation === "decrement" && newReceiptNo > firstReceiptNo) {
        setnewReceiptNo(newReceiptNo - 1);
        fetchAllDetails(newReceiptNo - 1);
      } else if (operation === "increment" && newReceiptNo < latestReceiptNo) {
        setnewReceiptNo(newReceiptNo + 1);
        fetchAllDetails(newReceiptNo + 1);
      } else if (operation === "first") {
        setnewReceiptNo(firstReceiptNo);
        fetchAllDetails(firstReceiptNo);
      } else if (operation === "latest") {
        setnewReceiptNo(latestReceiptNo - 1);
        fetchAllDetails(latestReceiptNo - 1);

        // setShowTable(false);
        // sethistoricreceipts(false);
        // setDivision(divisionState);
        // sethinvoice("");
        // setDate(new Date().toISOString());
        // sethtotal("");
        // setnarration("");
        // setselpaymentmode("");
        // setreceivedamt("");
        // setchangegiven("");
        // setvatperc("");
        // setlevyperc("");
        // setalreadypaidamt("");
        // sethtillno("");
        // sethvat("");
        // sethlevy("");
        // setCharge("");
        // sethistoric([]);
      }
    } catch (error) {
      console.error("Error handling button click:", error);
    }
  };

  const getLatestReceiptNo = async () => {
    try {
      const response = await axios.get(`${BASEURL}/getNewReceiptNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      });

      return response.data[0].RECIEPTNO;
    } catch (error) {
      console.error("Error fetching New Receipt No:", error);
    }
  };

  const handleReceipts = (row) => {
    setShowTable(true);
    sethistoricreceipts(true);
    setDivision(row.DIVISION);
    setnewReceiptNo(row.RECEIPTNO);
    sethinvoice(row.CASHSALENO);
    setDate(row.RECEIPTDATE);
    sethtotal(row.RECEIPTAMOUNT);
    setnarration(row.RECEIPTNARRATION);
    setselpaymentmode(row.PAYMODE);
    setreceivedamt(row.MONEYGIVEN);
    setchangegiven(row.CHANGEGIVEN);
    setvatperc(16);
    setlevyperc(2);

    axios
      .post(
        `${BASEURL}/getCashSaleHistoryDetails`,
        {
          invoiceno: row.CASHSALENO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        sethistoric(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Cash Sale History Details:", error);
      });

    axios
      .post(
        `${BASEURL}/getCashSaleDetailsInReceipt`,
        {
          invoice: row.CASHSALENO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        sethtillno(response.data[0].TILLNO);
        sethvat(response.data[0]["VAT AMT"]);
        sethlevy(response.data[0].LEVYAMOUNT);
        setCharge(response.data[0]["AMOUNT INCLUSIVE VAT"]);
      })
      .catch((error) => {
        console.error("Error fetching Cash Sale History Details:", error);
      });

    axios
      .post(
        `${BASEURL}/getAlreadyPaidAmount`,
        {
          cashsaleno: row.CASHSALENO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setalreadypaidamt(response.data[0].ALREADYPAIDAMT);
      })
      .catch((error) => {
        console.error("Error fetching Amount Paid Details:", error);
      });
  };

  async function SaveReceipts() {
    try {
      const response = await axios.post(
        `${BASEURL}/InsertReceiptDetails`,
        {
          RECIEPTNO: newReceiptNo,
          RECIEPTINVNO: invoiceno,
          RECIEPTDATE: date,
          RECIEPTAMOUNT: totalamount,
          RECIEPTNARRATION: narration,
          RECIEPTPAYMODE: selpaymentmode,
          RECIEPTPAYREFNO: "",
          RECIEPTPAYREFACCOUNT: "",
          RECIEPTCREATEDDATE: date,
          RECIEPTCREATEDBY: userState.emailId.split("@")[0].toUpperCase(),
          RECIEPTDIVISION: division,
          RECIEPTMONEYGIVEN: receivedamt,
          RECIEPTCHANGEGIVEN: changegiven,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Headers saved successfully`);
      } else {
        console.error(`Error while saving headers`);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
    }
  }

  return (
    <>
      <Navbar />
      {isloading ? (
        <Loading />
      ) : (
        <div className="h-auto w-full mt-4">
          {/* 1st row */}
          <section className="lg:flex">
            <div className="flex felx-row ml-2">
              <p className="text-red-600">Division:</p>
              <p className="ml-2 lg:ml-20">
                {division ? division : divisionState}
              </p>
              <p className="text-red-600 ml-[100px] lg:ml-32">Till No:</p>
              <input
                className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={htillno ? htillno : tillno}
                readOnly
                disabled={historicreceipts == true}
              ></input>
            </div>
            <div className="flex felx-row ml-2 my-1 lg:my-0">
              <p className="text-red-600 ml-2 lg:ml-32">User ID:</p>
              <input
                className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                disabled={historicreceipts == true}
              ></input>
              <p className="text-red-600 ml-3 lg:ml-32 mr-2">Receipt Date:</p>
              <input
                className="w-32 h-8 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="date"
                value={date.split("T")[0]}
                disabled={historicreceipts == true}
              ></input>
            </div>
          </section>
          {/* 2nd row */}
          <div className="flex felx-row mt-2 ml-[10px]">
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("first");
              }}
            >
              {"<<"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("decrement");
              }}
            >
              {"<"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("increment");
              }}
            >
              {">"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("latest");
              }}
            >
              {">>"}
            </button>
          </div>
          <section className="lg:flex">
            <div className="flex felx-row ml-2 mt-2">
              <p className="text-red-600">Receipt No:</p>
              <input
                className="w-24 h-8 border border-black ml-14 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={newReceiptNo}
                readOnly
              ></input>
              <button
                className="flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-1 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  setcashsalepopup(true);
                }}
              >
                ...
              </button>
              {cashsalepopup && (
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[98%] lg:w-[900px] p-1 overflow-y-auto">
                  <section className="lg:flex">
                    <div className="flex flex-row mt-2">
                      <p className="text-red-600 ml-2 w-[70px]">Search:</p>
                      <input
                        className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="flex flex-row mt-2">
                      <p className="text-red-600 ml-2">Start Date:</p>
                      <input
                        type="date"
                        className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        onChange={(e) => {
                          setstartdate(e.target.value);
                        }}
                        value={startdate.split("T")[0]}
                      ></input>
                      <p className="text-red-600 ml-2">End Date:</p>
                      <input
                        type="date"
                        className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        onChange={(e) => {
                          setEnddate(e.target.value);
                        }}
                        value={enddate.split("T")[0]}
                      ></input>
                      <button
                        className="flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300 mx-2"
                        onClick={() => setcashsalepopup(false)}
                      >
                        Close
                      </button>
                    </div>
                  </section>
                  <aside className="table__pos__app w-[98%]">
                    <table class="custom-table ">
                      <thead class="header-cell">
                        <tr>
                          <td>Receipt No</td>
                          <td>CashSaleNo</td>
                          <td>Receipt Date</td>
                          <td>Paymode</td>
                          <td>Division</td>
                          <td>Amount</td>
                        </tr>
                      </thead>
                      <tbody>
                        {filtHistoric.map((row, index) => (
                          <tr
                            key={row.INVNO}
                            onClick={() => {
                              setcashsalepopup(false);
                              handleReceipts(row);
                            }}
                            className={
                              index < receiptdata.length
                                ? "border-b cursor-pointer text-sm table-row"
                                : ""
                            }
                          >
                            <td className="text-right pr-8">
                              {row.RECEIPTNO ? row.RECEIPTNO : ""}
                            </td>
                            <td className="text-right pr-8">
                              {row.CASHSALENO ? row.CASHSALENO : ""}
                            </td>
                            <td>
                              {row.RECEIPTDATE
                                ? moment(row.RECEIPTDATE.split("T")[0]).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""}
                            </td>
                            <td>{row.PAYMODE ? row.PAYMODE : ""}</td>
                            <td>{row.DIVISION ? row.DIVISION : ""}</td>
                            <td className="text-right">
                              {row.RECEIPTAMOUNT
                                ? row.RECEIPTAMOUNT.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </aside>
                </div>
              )}
              <p className="text-red-600 ml-[125px] lg:ml-12 ">Invoice No:</p>
              <input
                className="w-32 h-8 border border-black ml-2 mr-2 p-2  focus:outline-none focus:border-blue-500 rounded"
                value={hinvoice ? hinvoice : invoiceno}
                readOnly
                disabled={historicreceipts == true}
              ></input>
            </div>
            <div className="flex felx-row ml-2 mt-2">
              <p className="text-red-600 w-[130px] lg:ml-16 mr-2">
                Payment Mode:
              </p>
              <Select
                styles={customStyless}
                onChange={(e) => {
                  setselpaymentmode(e.value);
                }}
                value={
                  selpaymentmode
                    ? { label: selpaymentmode, value: selpaymentmode }
                    : { label: "", value: "" }
                }
                options={listofpaymentmodes.map((item) => ({
                  label: item.PAYMODE,
                  value: item.PAYMODE,
                }))}
                isDisabled={historicreceipts == true}
              />
              <p className="text-red-600 ml-24">Total:</p>
              <input
                className="w-32 h-8 border border-black ml-6 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={
                  htotal
                    ? htotal.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : totalamount
                    ? totalamount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
                disabled={historicreceipts == true}
              ></input>
            </div>
          </section>
          {/* 3rd row */}
          <div className="flex felx-row ml-2 mt-2">
            <p className="text-red-600 mr-1">Already Paid Amt:</p>
            <input
              className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
              value={
                alreadypaidamt
                  ? alreadypaidamt.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"
              }
              disabled
            ></input>
            <p className="text-red-600 ml-10">MPESA Ref No:</p>
            <div className="lg:flex">
              <input
                className="w-[150px] h-8 border border-black ml-1 mr-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                disabled={historicreceipts == true}
              ></input>
              <input
                className="w-[125px] h-8 border border-black ml-1 mr-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                disabled={historicreceipts == true}
              ></input>
              <div>
                <input
                  className="w-[200px] h-8 border border-black ml-1 mr-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  disabled={historicreceipts == true}
                ></input>
              </div>
            </div>
          </div>
          {/* 4th row */}
          <div className="flex felx-row ml-2 mt-2">
            <p className="text-red-600">Pending to Pay:</p>
            <input
              className="w-32 h-8 border border-black ml-[28px] mr-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
              value={pendingtopay?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              readOnly
              disabled={historicreceipts == true}
            ></input>
            <p className="text-red-600 ml-4">Receipt Amount:</p>
            <input
              className="w-32 h-8 border border-black ml-[11px] mr-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
              type="number"
              value={
                charge
                  ? charge.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
              onChange={(e) => {
                setCharge(e.target.value);
              }}
              disabled={historicreceipts}
            ></input>

            {/* <button className="flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-1 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300">
            ...
          </button> */}
          </div>
          {/* 6th row */}
          <div className="lg:flex flex-row ml-2 mt-2">
            <div className="flex flex-row">
              <p className="text-red-600">Narration:</p>
              <input
                type="text"
                className="w-[500px] h-20 border border-black ml-16 mr-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={narration}
                onChange={(e) => {
                  setnarration(e.target.value);
                }}
                disabled={historicreceipts == true}
              ></input>
            </div>
            <div className="flex flex-col lg:ml-8">
              <div className="flex flex-row mt-2">
                <p className="text-red-600">Vat % and Amt:</p>
                <input
                  className="w-20 h-8 border border-black ml-6 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    vatperc
                      ? vatperc.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                  disabled={historicreceipts == true}
                ></input>
                <input
                  className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    hvat
                      ? hvat.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : totalvat
                      ? totalvat.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                  disabled={historicreceipts == true}
                ></input>
              </div>
              <div className="flex flex-row mt-2">
                <p className="text-red-600">Levy % and Amt:</p>
                <input
                  className="w-20 h-8 border border-black ml-4 mr-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    levyperc
                      ? levyperc.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                  disabled={historicreceipts == true}
                ></input>
                <input
                  className="w-32 h-8 border border-black ml-2 mr-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    hlevy
                      ? hlevy.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : totallevy
                      ? totallevy.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                  disabled={historicreceipts == true}
                ></input>
              </div>
            </div>
          </div>
          {/* 7th row*/}
          <section>
            <div className="lg:flex felx-row ml-2 mt-2 items-center">
              <aside className="flex">
                <p className="text-red-600">Cash Received:</p>
                <input
                  className="w-32 h-8 border border-black ml-8 mr-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    receivedamt
                      ? receivedamt.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  onChange={(e) => {
                    setreceivedamt(e.target.value);
                    setchangegiven(
                      Number(e.target.value) - Number(pendingtopay)
                    );
                  }}
                  disabled={historicreceipts == true}
                ></input>

                <p className="text-red-600 ml-4">Change given:</p>
                <input
                  className="w-32 h-8 border border-black ml-1 mr-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    changegiven
                      ? changegiven.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  disabled
                ></input>
              </aside>
              <section className="flex justify-between lg:gap-[150px] my-3">
                <div className="flex">
                  <button
                    className="flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-1 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Refresh
                  </button>
                  {/* <button className="flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-1 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300">
                    Remove
                  </button> */}
                </div>
                <div className="flex gap-2">
                  <button
                    className=" flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-1 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                    onClick={async () => {
                      if (historicreceipts) {
                        alert("Cannot Save Hsitoric Receipts");
                      } else {
                        if (!newReceiptNo) {
                          alert("Kindly Provide Receipt No");
                        } else if (!invoiceno) {
                          alert("Kindly Provide Invoice No");
                        } else if (!selpaymentmode) {
                          alert("Kindly Choose Payment Mode");
                        } else if (!receivedamt) {
                          alert("Kindly Supply Amount Received");
                        } else if (!changegiven) {
                          alert("Kindly Supply Change Given");
                        } else if (Number(charge) > Number(pendingtopay)) {
                          alert(
                            "Cannot add money more than pending to be paid"
                          );
                        } else {
                          await SaveReceipts();
                          alert("Receipt Saved Succesfully");
                          window.location.reload();
                        }
                      }
                    }}
                  >
                    Save
                  </button>
                  {/* <button className="flex items-center justify-center border border-black bg-gray-600 text-white rounded-md px-3 py-1 ml-1 mr-2 hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300">
                    Print Receipt
                  </button>
                  <button className="flex items-center justify-center border border-black bg-blue-600 text-white rounded-md px-3 py-1 ml-1 mr-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300">
                    Print for Bill Paid
                  </button>{" "} */}
                </div>
              </section>
            </div>
          </section>
          {/* Table */}
          <div className="w-full h-auto p-1 mt-1">
            {ShowTable && (
              <aside className="table__pos__app text-sm">
                <table class="custom-table">
                  <thead class="header-cell">
                    <tr>
                      <td>Product Code</td>
                      <td>Product Name</td>
                      <td>Quantity</td>
                      <td>Rate (Exclusive)</td>
                      <td>VAT Amount</td>
                      <td>Levy Amount</td>
                      <td>Rate (Inclusive)</td>
                      <td>Total Amount</td>
                      <td>Discount</td>
                      <td>Associated</td>
                    </tr>
                  </thead>
                  <tbody>
                    {historic.length > 0
                      ? historic.map((value, index) => (
                          <tr
                            className={
                              index < historic.length
                                ? "border-b cursor-pointer text-sm table-row"
                                : ""
                            }
                          >
                            <td>{value["ITEM CODE"]}</td>
                            <td>{value["ITEM NAME"]}</td>
                            <td className="text-right pr-6">{value.QTY}</td>
                            <td className="text-right pr-6">
                              {value["ITEM VALUE EXC VAT"].toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td className="text-right pr-6">
                              {value.VATAMOUNT
                                ? value.VATAMOUNT.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </td>
                            <td className="text-right pr-6">
                              {value.LEVYAMOUNT
                                ? value.LEVYAMOUNT.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </td>

                            <td className="text-right pr-6">
                              {value["ITEM VALUE"].toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className="text-right pr-6">
                              {(
                                Number(value.QTY) * Number(value["ITEM VALUE"])
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className="text-right pr-6">
                              {value["ITEM DISC"].toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td>
                              {value.OT_INV_ITEM_ASSOCIATED == 1 ? "YES" : "NO"}
                            </td>
                          </tr>
                        ))
                      : cashsaledata.map((value, index) => {
                          return (
                            <tr
                              className={
                                index < cashsaledata.length
                                  ? "border-b cursor-pointer text-xs"
                                  : ""
                              }
                            >
                              <td>{value["ITEM CODE"]}</td>
                              <td>{value["ITEM NAME"]}</td>
                              <td className="text-right">{value.QTY}</td>
                              <td className="text-right">
                                {value["ITEM VALUE EXC VAT"].toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td className="text-right">
                                {value.VATAMOUNT
                                  ? value.VATAMOUNT.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : ""}
                              </td>
                              <td className="text-right">
                                {value.LEVYAMOUNT
                                  ? value.LEVYAMOUNT.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : ""}
                              </td>

                              <td className="text-right">
                                {value["ITEM VALUE"].toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-right">
                                {(
                                  Number(value.QTY) *
                                  Number(value["ITEM VALUE"])
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-right">
                                {value["ITEM DISC"].toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                {value.OT_INV_ITEM_ASSOCIATED == 1
                                  ? "YES"
                                  : "NO"}
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </aside>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Receipt;
