import Select from "react-select";
import { React, useEffect, useState, useReducer } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IoFastFoodOutline } from "react-icons/io5";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const initialState = {
  selectedItems: [],
  selectedQuantities: {},
};

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "TOGGLE_ITEM":
//       const itemIndex = state.selectedItems?.indexOf(action.item);
//       if (itemIndex === -1) {
//         return {
//           ...state,
//           selectedItems: [...state.selectedItems, action.item],
//           selectedQuantities: {
//             ...state.selectedQuantities,
//             [action.item]: 1, // Initial quantity is 1
//           },
//         };
//       } else {
//         const updatedItems = [...state.selectedItems];
//         updatedItems.splice(itemIndex, 1);
//         const updatedQuantities = { ...state.selectedQuantities };
//         delete updatedQuantities[action.item];
//         return {
//           ...state,
//           selectedItems: updatedItems,
//           selectedQuantities: updatedQuantities,
//         };
//       }
//     case "UPDATE_QUANTITY":
//       return {
//         ...state,
//         selectedQuantities: {
//           ...state.selectedQuantities,
//           [action.item]: action.quantity,
//         },
//       };
//     default:
//       return state;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_ITEM":
      const itemIndex = state.selectedItems?.indexOf(action.item);
      if (itemIndex === -1) {
        return {
          ...state,
          selectedItems: [...state.selectedItems, action.item],
          selectedQuantities: {
            ...state.selectedQuantities,
            [action.item.OM_ITEM_CODE]:
              state.selectedQuantities[action.item.OM_ITEM_CODE] || 1, // Initial quantity is 1 only if not already set
          },
        };
      } else {
        const updatedItems = [...state.selectedItems];
        updatedItems.splice(itemIndex, 1);
        const updatedQuantities = { ...state.selectedQuantities };
        delete updatedQuantities[action.item.OM_ITEM_CODE];
        return {
          ...state,
          selectedItems: updatedItems,
          selectedQuantities: updatedQuantities,
        };
      }
    case "UPDATE_QUANTITY":
      return {
        ...state,
        selectedQuantities: {
          ...state.selectedQuantities,
          [action.item.OM_ITEM_CODE]: action.quantity,
        },
        selectedItems: state.selectedItems.includes(action.item)
          ? state.selectedItems
          : [...state.selectedItems, action.item],
      };

    default:
      return state;
  }
};

const TableOrders = () => {
  const [selTable, setSelTable] = useState("");
  const [itemsPopup, setItemsPopUp] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const divisionState = useSelector((state) => state.changeDivision);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [category, setcategory] = useState([]);
  const [itemdetails, setItemdetails] = useState([]);
  const [selcategory, setselcategory] = useState("");
  const [tables, setTables] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [table, setistable] = useState(true);
  const [ShowTable, setShowTable] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  let selectedItem = [];

  const handleRowClick = (item) => {
    dispatch({ type: "TOGGLE_ITEM", item });
  };

  const handleQuantityChange = (item, quantity) => {
    dispatch({ type: "UPDATE_QUANTITY", item, quantity });
  };

  selectedItem = state?.selectedItems;

  useEffect(() => {
    getALLTables();
    getAllCategories();
  }, []);

  const getALLTables = () => {
    axios
      .post(
        `${BASEURL}/getListOfTables`,
        {
          division: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setTables(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Table details:", error);
      });
  };

  const getAllCategories = () => {
    axios
      .get(`${BASEURL}/getallcategories`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setcategory(response.data);

        setisloading(false);
        setistable(false);
      })
      .catch((error) => {
        setisloading(false);
        setistable(false);
        console.error("Error fetching Main Categories:", error);
      });
  };

  const getItemDetails = (value) => {
    axios
      .post(
        `${BASEURL}/getItemDetails`,
        {
          category: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setItemdetails(response.data);
        setShowTable(true);
        setistable(false);
      })
      .catch((error) => {
        setistable(false);
        console.error("Error fetching List Of Items:", error);
      });
  };

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  return (
    <>
      <Navbar />
      <div className="w-[100%] flex justify-center">
        <div className="bg-slate-100 m-2 h-auto py-2 px-10 w-[60%] rounded mt-4 shadow-lg">
          <div className="flex gap-2">
            <span>Order Number:</span>
            <input className="w-24 rounded-md h-7"></input>
            <span>Table Number:</span>
            <Select
              styles={customStyless}
              onChange={(e) => {
                setSelTable(e.value);
              }}
              value={
                selTable
                  ? { label: selTable, value: selTable }
                  : { label: "", value: "" }
              }
              options={[
                { label: "", value: "" }, // Empty option
                ...tables?.map((item) => ({
                  label: item.TABLENUMBER,
                  value: item.TABLENUMBER,
                })),
              ]}
            />
            <span>Date:</span>
            <input className="w-auto rounded-md h-7" type="date"></input>
          </div>

          {/* 2nd Row */}
          <div className="flex gap-4 mt-4">
            <span>Waiter Name:</span>
            <input className="w-80 rounded-md h-7"></input>

            <button
              className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-6  text-white"
              onClick={() => setItemsPopUp(true)}
            >
              Select Items
            </button>
          </div>
          {itemsPopup && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[85%] w-[95%] p-1 overflow-y-auto rounded-md">
              <div>
                <div className="w-auto flex flex-row">
                  {/* left side */}
                  <div className="w-[620px] flex flex-wrap overflow-y-auto max-h-[500px]">
                    {category.map((item, index) => (
                      <aside
                        key={index}
                        className={`rounded-md border-slate-200 border shadow-xl text-center p-2 ml-2 mt-2 cursor-pointer w-24 flex flex-col items-center ${
                          selcategory === item.MAINCATEGORY
                            ? "bg-blue-200"
                            : "bg-white"
                        }`}
                        onClick={() => {
                          setistable(true);
                          getItemDetails(item.MAINCATEGORY);
                          setselcategory(item.MAINCATEGORY);
                        }}
                      >
                        {item.CATEGORYIMAGE?.data ? (
                          <>
                            {" "}
                            <img
                              className="text-amber-950"
                              src={URL.createObjectURL(
                                new Blob(
                                  [new Uint8Array(item.CATEGORYIMAGE?.data)],
                                  {
                                    type: "image/jpeg",
                                  }
                                )
                              )}
                            />
                          </>
                        ) : (
                          <IoFastFoodOutline
                            size={50}
                            className="text-amber-950"
                          />
                        )}

                        <p className="text-xs">{item.MAINCATEGORY}</p>
                      </aside>
                    ))}
                  </div>

                  {/* right side */}

                  <div className="w-[800px] h-auto pb-2">
                    <div className="flex justify-end">
                      <IoClose size={30} onClick={() => setItemsPopUp(false)} />
                    </div>

                    {table ? (
                      <Loading />
                    ) : (
                      ShowTable && (
                        <aside className="table__pos__app flex flex-col">
                          <div className="flex gap-2 mb-4">
                            <span>Search :</span>
                            <input className="w-48 rounded-md outline"></input>
                          </div>
                          <table class="custom-table w-[98%]">
                            <thead class="header-cell">
                              <tr>
                                <td className="text-left">ITEM CODE</td>
                                <td className="text-left">ITEM NAME</td>
                                <td className="text-left">UOM</td>
                                <td className="text-left">STOCK QTY</td>
                                <td className="text-left">RATE</td>
                                <td className="text-left">REQUIRED QTY</td>
                              </tr>
                            </thead>
                            <tbody>
                              {itemdetails.map((item) => {
                                return (
                                  <tr
                                    className={`table-row cursor-pointer ${
                                      selectedItem.length > 0
                                        ? "bg-yellow-300"
                                        : "bg-transparent"
                                    } `}
                                    key={item.OM_ITEM_CODE}
                                    onClick={() => handleRowClick(item)}
                                  >
                                    <td className="text-left">
                                      {item.OM_ITEM_CODE}
                                    </td>
                                    <td
                                      className="text-left"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      {item.OM_ITEM_DESCRIPTION}
                                    </td>
                                    <td className="text-left pl-2">
                                      {item.UOM}
                                    </td>
                                    <td className="text-right pr-2">
                                      {item.STOCKQTY}
                                    </td>
                                    <td className="text-right">
                                      {item.SELLINGPRICE
                                        ? item.SELLINGPRICE.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-right pr-2">
                                      <input
                                        className="w-20 border text-right"
                                        type="number"
                                        value={
                                          state?.selectedQuantities[
                                            item.OM_ITEM_CODE
                                          ] || ""
                                        }
                                        onChange={(e) => {
                                          if (
                                            Number(e.target.value) >
                                            Number(item.STOCKQTY)
                                          ) {
                                            alert(
                                              "You are exceeding the limit"
                                            );
                                          } else {
                                            handleQuantityChange(
                                              item,
                                              parseInt(e.target.value)
                                            );
                                          }
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                      ></input>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </aside>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Selected Items */}
          {selectedItem.length > 0 && (
            <>
              <aside className="table__pos__app flex flex-col">
                <table class="custom-table w-[98%]">
                  <thead class="header-cell">
                    <tr>
                      <td className="text-left">ITEM CODE</td>
                      <td className="text-left">ITEM NAME</td>
                      <td className="text-left">UOM</td>
                      <td className="text-left">STOCK QTY</td>
                      <td className="text-left">RATE</td>
                      <td className="text-left">REQUIRED QTY</td>
                      <td className="text-left">TOTAL AMOUNT</td>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedItem?.map((item) => {
                      return (
                        <tr
                          className="table-row cursor-pointer"
                          key={item.OM_ITEM_CODE}
                          onClick={() => handleRowClick(item)}
                        >
                          <td className="text-left">{item.OM_ITEM_CODE}</td>
                          <td
                            className="text-left"
                            style={{ maxWidth: "200px" }}
                          >
                            {item.OM_ITEM_DESCRIPTION}
                          </td>
                          <td className="text-left pl-2">{item.UOM}</td>
                          <td className="text-right pr-2">{item.STOCKQTY}</td>
                          <td className="text-right">
                            {item.SELLINGPRICE
                              ? item.SELLINGPRICE.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                          <td className="text-right pr-2">
                            <input
                              className="w-20 border text-right"
                              type="number"
                              value={
                                state?.selectedQuantities[item.OM_ITEM_CODE] ||
                                ""
                              }
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) > Number(item.STOCKQTY)
                                ) {
                                  alert("You are exceeding the limit");
                                } else {
                                  handleQuantityChange(
                                    item,
                                    parseInt(e.target.value)
                                  );
                                }
                              }}
                              onClick={(e) => e.stopPropagation()}
                            ></input>
                          </td>
                          <td className="text-right">
                            {item.SELLINGPRICE
                              ? Number(
                                  Number(
                                    state?.selectedQuantities[item.OM_ITEM_CODE]
                                  ) * Number(item.SELLINGPRICE)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </aside>

              <div className="w-full flex justify-center">
                <button className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-6  text-white">
                  Place Order
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TableOrders;
