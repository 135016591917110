import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import KitchenNavbar from "../../Components/KitchenNavbar";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setDivision } from "../../Redux/Action/division";
import { useNavigate } from "react-router-dom";
import ListofOrdersKitchen from "./ListofOrdersKitchen";
import ListofOrdersNotStarted from "./ListofOrdersNotStarted";

const KitchenDashboard = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.changeTheState?.user);
  const loggedInUser = userState?.emailId;
  const [userdivisionRights, setuserDivisionList] = useState([]);
  const divisionState = useSelector((state) => state.changeDivision);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${BASEURL}/getdivisionRights?USEREMAIL=${loggedInUser}`)
      .then((response) => {
        console.log(response.data);
        setuserDivisionList(response?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-slate-100 min-h-[100vh]">
      <KitchenNavbar />
      <div className="flex justify-center font-bold text-[15px] mt-2">
        <p>Division:</p>
        <select
          onChange={(e) => {
            dispatch(setDivision(e.target.value));
          }}
          className="lg:w-[300px] w-[250px]  border-black border ml-2 mr-2 rounded"
        >
          <option>{divisionState}</option>
          {userdivisionRights.map((item) =>
            item.OM_USER_DIVISION !== divisionState ? (
              <option key={item.OM_USER_DIVISION} value={item.OM_USER_DIVISION}>
                {item.OM_USER_DIVISION}
              </option>
            ) : null
          )}
        </select>
      </div>

      <section className="lg:w-full">
        <div className="lg:flex justify-center">
          <div className="w-full lg:w-1/2 p-2 lg:flex justify-center">
            <ListofOrdersNotStarted division={divisionState} />
          </div>
          <div className="w-full lg:w-1/2 p-2 lg:flex justify-center">
            <ListofOrdersKitchen division={divisionState} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default KitchenDashboard;
