import React, { useEffect, useState } from "react";
import "../CSS/SupplierMaster.css";
import axios from "axios";
import AllPageNavbar from "../Components/AllPageNavbar";
import Loading from "../Components/Loading";
import { Country, City } from "country-state-city";
import Select from "react-select";

const SupplierCreation = () => {
  const [date, setDate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [supplierCode, setSupplierCode] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [creditDays, setCreditDays] = useState("");
  const [adress, setAdress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [tableData, setTableData] = useState([]);
  const [fetchrow, setfetchrow] = useState(false);
  const [isloading, setisloading] = useState(true);
  // const [latitude, setLatitude] = useState(null);
  // const [longitude, setLongitude] = useState(null);
  const [cities, setCities] = useState([]);
  const countries = Country.getAllCountries();
  const [search, setSearch] = useState("");

  const customStyless1 = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "170px",
      maxWidth: "170px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "1px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filtProducts = tableData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "VENDORCODE",
        "VENDNAME",
        "CREDITLIMIT",
        "CREDITDAYS",
        "TELEPHONE",
        "EMAILADDRESS",
        "CITY",
        "COUNTRY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //       },
  //       (error) => {
  //       }
  //     );
  //   } else {
  //   }
  //   getNewSupplierCode();
  //   getSupplierList();
  // }, []);

  // useEffect(() => {
  //   if (latitude !== null && longitude !== null) {
  //     axios
  //       .get(
  //         `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
  //       )
  //       .then((response) => {
  //         setCountry(response?.data?.address?.country);
  //         setCity(
  //           response?.data?.address?.city
  //             ? response?.data?.address?.city
  //             : response?.data?.address?.state
  //         );
  //       })
  //       .catch((error) => {
  //         console.log(error.message);
  //       });
  //   }
  // }, [latitude, longitude]);

  useEffect(() => {
    getNewSupplierCode();
    getSupplierList();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.label);
    setCity("");

    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;

      const countryCities = City.getCitiesOfCountry(selectedCountryIso);
      setCities(countryCities);
    } else {
      setCities([]);
    }
  };

  const getNewSupplierCode = () => {
    axios
      .get(`${BASEURL}/getNewSupplierCode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setSupplierCode(response.data[0].NEWVENDORCODE);
      })
      .catch((error) => {
        console.error("Error fetching New Supplier Code Details:", error);
      });
  };

  const getSupplierList = () => {
    axios
      .get(`${BASEURL}/getListOfSupplier`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setTableData(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  const handleRowClick = (value, index) => {
    setfetchrow(true);
    setSupplierCode(value.VENDORCODE);
    setSupplierName(value.VENDNAME);
    setMobileNo(value.TELEPHONE);
    setEmailId(value.EMAILADDRESS);
    setAdress(value.ADDRESS1);
    setCity(value.CITY);
    setCountry(value.COUNTRY);
    setCreditLimit(value.CREDITLIMIT);
    setCreditDays(value.CREDITDAYS);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Saving
  async function SaveSupplyMaster(type) {
    try {
      const response = await axios.post(`${BASEURL}/insertSupplier/${type}`, {
        VENDCODE: supplierCode,
        VENDNAME: supplierName,
        VENDADDRESS: adress,
        VENDPHONE: mobileNo,
        VENDEMAIL: emailId,
        VENDCREDITLIMIT: Number(creditLimit),
        VENDCREDITDAYS: Number(creditDays),
        VENDCOUNTRY: country,
        VENDCITY: city,
      });

      if (response.status === 200) {
        console.log(`Supplier Master saved successfully`);
      } else {
        console.error(`Error while saving Supplier Master`);
      }
    } catch (error) {
      console.error(`Error while saving Supplier Master`, error);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <AllPageNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page"> Supplier Master</h1>
        <div className="my-1">
          <button
            className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
            onClick={() => {
              if (supplierCode || supplierName) {
                if (window.confirm("Are you sure you don't want to Save")) {
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            }}
          >
            New
          </button>
          <button
            className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
            onClick={async () => {
              if (!supplierCode) {
                alert("Kindly Supply Supplier Code");
              } else if (!supplierName) {
                alert("Kindly Supply Supplier Name");
              } else if (!mobileNo) {
                alert("Kindly Supply Mobile Number");
              } else if (!emailId) {
                alert("Kindly Supply Email Id");
              } else if (!creditLimit) {
                alert("Kindly Supply Credit Limit");
              } else if (!creditDays) {
                alert("Kindly Supply Credit Days");
              } else if (!adress) {
                alert("Kindly Supply Address");
              } else if (!city) {
                alert("Kindly Supply City");
              } else if (!country) {
                alert("Kindly Supply Country");
              } else {
                await SaveSupplyMaster("new");
                alert("Saved Succesfully");
                window.location.reload();
              }
            }}
          >
            Save
          </button>
          <button
            className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
            onClick={async () => {
              if (!supplierCode) {
                alert("Kindly Supply Supplier Code");
              } else if (!supplierName) {
                alert("Kindly Supply Supplier Name");
              } else if (!mobileNo) {
                alert("Kindly Supply Mobile Number");
              } else if (!emailId) {
                alert("Kindly Supply Email Id");
              } else if (!creditLimit) {
                alert("Kindly Supply Credit Limit");
              } else if (!creditDays) {
                alert("Kindly Supply Credit Days");
              } else if (!adress) {
                alert("Kindly Supply Address");
              } else if (!city) {
                alert("Kindly Supply City");
              } else if (!country) {
                alert("Kindly Supply Country");
              } else {
                await SaveSupplyMaster("Update");
                alert("Saved Succesfully");
                window.location.reload();
              }
            }}
          >
            Update
          </button>
        </div>
      </header>

      <section className="section__Item">
        <div className="section__div">
          <div className="item_second_line_supplier">
            <div className="supplier_code">
              <p>Supplier Code</p>
              <input
                type="text"
                value={supplierCode}
                // readOnly
                onChange={(e) => {
                  setSupplierCode(e.target.value);
                }}
                disabled={fetchrow == true}
              />
            </div>
            <div className="supplier_name">
              <p>Supplier Name</p>
              <input
                type="text"
                value={supplierName}
                onChange={(e) => {
                  setSupplierName(e.target.value);
                }}
                disabled={fetchrow == true}
              />
            </div>
            <div className="supplier_country">
              <p>Credit Limit</p>
              <input
                type="number"
                value={creditLimit}
                onChange={(e) => {
                  setCreditLimit(e.target.value);
                }}
              />
            </div>
            <div className="supplier_city">
              <p>Credit Days</p>
              <input
                type="number"
                className=""
                value={creditDays}
                onChange={(e) => {
                  setCreditDays(e.target.value);
                }}
              />{" "}
            </div>
          </div>
          <div className="item_third_line">
            <div className="supplier_code">
              <p>Mobile No</p>
              <input
                type="number"
                value={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                }}
              />
            </div>
            <div className="supplier_name">
              <p>Email</p>
              <input
                type="text"
                value={emailId}
                onChange={(e) => {
                  setEmailId(e.target.value);
                }}
              />
            </div>

            <div className="supplier_country">
              <p>Country</p>
              <Select
                styles={customStyless1}
                value={
                  country
                    ? { label: country, value: country }
                    : { label: "", value: "" }
                }
                onChange={handleCountryChange}
                options={[
                  // { label: " ", value: "" },
                  ...countries.map((item) => ({
                    label: item.name,
                    value: item.isoCode,
                  })),
                ]}
              />
            </div>

            <div className="supplier_city">
              <p>City</p>
              <Select
                styles={customStyless1}
                value={
                  city ? { label: city, value: city } : { label: "", value: "" }
                }
                onChange={(e) => {
                  setCity(e.value);
                }}
                options={[
                  // { label: " ", value: "" },
                  ...cities.map((item) => ({
                    label: item.name,
                    value: item.name,
                  })),
                ]}
                onBlur={() => {
                  if (country && !city) {
                    alert("Kindly Enter City Details ");
                  }
                }}
              />
            </div>
          </div>
          <div className="item_fourth_line">
            <div className="description">
              <p>Address</p>
              <textarea
                name=""
                id=""
                className="text__area__item"
                value={adress}
                onChange={(e) => {
                  setAdress(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="table__pos__app w-[98%]  ">
          <div className="lg:flex">
            <p className="lg:mx-2 text-lg">Search</p>
            <input
              className="w-[200px] lg:mr-[50px]"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
          </div>
          <table class="custom-table ">
            <thead class="header-cell">
              <tr>
                <td>SUPPLIER CODE</td>
                <td>SUPPLIER NAME</td>
                <td>CREDIT LIMIT</td>
                <td>CREDIT DAYS</td>
                <td>MOBILE NUMBER</td>
                <td>EMAIL</td>
                <td>CITY</td>
                <td>COUNTRY</td>
              </tr>
            </thead>
            <tbody>
              {filtProducts.map((value, index) => (
                <tr
                  className="table-row"
                  onClick={() => {
                    handleRowClick(value, index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ textAlign: "right", paddingRight: "30px" }}>
                    {value.VENDORCODE ? value.VENDORCODE : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.VENDNAME ? value.VENDNAME : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "25px" }}>
                    {value.CREDITLIMIT
                      ? value.CREDITLIMIT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "25px" }}>
                    {value.CREDITDAYS || "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "25px" }}>
                    {value.TELEPHONE || "N/A"}
                  </td>
                  <td style={{ paddingLeft: "20px" }}>
                    {value.EMAILADDRESS || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.CITY || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.COUNTRY || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default SupplierCreation;
