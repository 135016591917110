import { React, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import "../CSS/MainReusable.css";
const TablesSeating = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const userState = useSelector((state) => state.changeTheState.user);
  const divisionState = useSelector((state) => state.changeDivision);
  const [listofdivisions, setlistofdivisions] = useState([]);
  const [seldivision, setseldivision] = useState("");
  const [data, setData] = useState([]);
  const [isloading, setisloading] = useState(true);
  const dispatch = useDispatch();
  const [tableNumber, setTableNumber] = useState("");
  const [seatingno, setSeatingNo] = useState(0);
  const [tableActive, setTableActive] = useState(1);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  useEffect(() => {
    axios
      .post(
        `${BASEURL}/getdivisionRights`,
        { EMAIL: userState.emailId },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        if (response.data.length > 0) {
          setlistofdivisions(response.data);
          if (!divisionState) {
            dispatch(setseldivision(response.data[0].OM_USER_DIVISION));
          }
          getListOfTablesSeating();
        } else {
          alert("User rights is not given");
          window.history.back();
        }
      });
  }, []);

  // useEffect(() => {
  //   ();
  //   getDivisionTables();
  // }, []);

  async function saveTableSeating() {
    if (seldivision && tableNumber && seatingno) {
      try {
        const response = await axios.post(
          `${BASEURL}/tableSeating`,
          {
            DIVISION: seldivision,
            TABLENUMBER: tableNumber,
            TABLESEATING: seatingno,
            TABLEACTIVE: tableActive,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
      } catch (error) {
        console.log(error);
        alert(error.response?.data?.err);
        window.location.reload();
      }
    } else {
      alert("Enter all the details");
    }
  }

  const getListOfTablesSeating = () => {
    axios
      .post(
        `${BASEURL}/getListOfTablesSeating`,
        {
          division: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Tables Seating:", error);
      });
  };

  const getDivisionTables = () => {
    axios
      .get(`${BASEURL}/getDivisionTables`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofdivisions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Divisions:", error);
      });
  };

  return (
    <>
      <Navbar />
      {isloading ? (
        <Loading />
      ) : (
        <div>
          <section className="lg:flex">
            <div className="flex flex-row mt-4 px-3">
              <p>Select Division:</p>
              &nbsp;
              <Select
                styles={customStyless}
                onChange={(e) => {
                  setseldivision(e.value);
                }}
                value={
                  seldivision
                    ? { label: seldivision, value: seldivision }
                    : { label: "", value: "" }
                }
                options={[
                  { label: "", value: "" }, // Empty option
                  ...listofdivisions.map((item) => ({
                    label: item.OM_USER_DIVISION,
                    value: item.OM_USER_DIVISION,
                  })),
                ]}
              />
              <p className="ml-2">Table Number:</p>
              <input
                onChange={(e) => {
                  setTableNumber(e.target.value);
                }}
                className="w-64 ml-4 mr-4 border border-black rounded"
              ></input>
            </div>
            <div className="flex flex-row mt-4 px-3">
              <p className="w-[100px] lg:w-[auto]">Seating:</p>
              <input
                onChange={(e) => {
                  setSeatingNo(e.target.value);
                }}
                className="w-32  ml-4 mr-2 border border-black rounded"
              ></input>
              <button
                className="mx-2   px-3 
        bg-[#002d62] text-white border border-black rounded"
              >
                ...
              </button>
              <input
                defaultChecked={tableActive}
                onChange={(e) => {
                  setTableActive(!tableActive);
                }}
                type="checkbox"
                className="mx-2"
              ></input>
              <p>Freeze</p>
              <button
                onClick={saveTableSeating}
                className=" bg-[#002d62] text-white px-2 mx-2 rounded"
              >
                Save Table
              </button>
            </div>
          </section>
          <aside className="table__pos__app ">
            <table class="custom-table">
              <thead class="header-cell">
                <tr className="bg-blue-900 text-white uppercase text-sm">
                  <td className="text-left">DIVISON</td>
                  <td className="text-left">TABLE NUMBER</td>
                  <td className="text-left">SEATING</td>
                  <td className="text-left">ACTIVE TABLE</td>
                </tr>
              </thead>
              <tbody>
                {data.map((value, index) => (
                  <tr
                    className={
                      index < data.length
                        ? "border-b cursor-pointer text-sm table-row"
                        : ""
                    }
                  >
                    <td className="text-left">
                      {value.DIVISION ? value.DIVISION : ""}
                    </td>
                    <td className="text-left">
                      {value.TABLENUMBER ? value.TABLENUMBER : ""}
                    </td>
                    <td className="text-right">
                      {value.TABLESEATING ? value.TABLESEATING : ""}
                    </td>
                    <td className="text-left">
                      <input
                        className="ml-2"
                        type="checkbox"
                        checked={value.ACTIVE == 1 ? true : false}
                        readOnly
                      ></input>
                      {/* {value.ACTIVE ? value.ACTIVE : ""} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </aside>
        </div>
      )}
    </>
  );
};

export default TablesSeating;
