const initialState = "";
const changeDivision = (state = initialState, action) => {
  switch (action.type) {
    case "DIVISION":
      return action.data;
    default:
      return state;
  }
};

export default changeDivision;
