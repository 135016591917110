import React, { useState } from "react";
import waiter from "../Images/waiter.png";
import delivery from "../Images/delivery.png";
import dashboard from "../Images/dashboard.png";
import { useNavigate, useLocation } from "react-router-dom";
import { BsBoxes } from "react-icons/bs";
import { FcBusinessman } from "react-icons/fc";
import { MdReceiptLong } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { GiRoundTable } from "react-icons/gi";
import { FaWhatsappSquare } from "react-icons/fa";
import { GiCash } from "react-icons/gi";
import { FaFileUpload } from "react-icons/fa";
import { IoIosListBox } from "react-icons/io";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (path) => {
    navigate(path);
    setSelectedItem(path);
  };

  const isItemSelected = (path) => {
    return selectedItem === path;
  };
  return (
    <div>
      <div className="w-full h-auto flex flex-row mb-1 mt-1 overflow-auto">
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/Inventory")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/Inventory")}
        >
          <BsBoxes size={40} className="text-yellow-500" />
          <p className="text-xs font-bold">Inventory</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/UploadCategoryImage")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/UploadCategoryImage")}
        >
          <FaFileUpload size={50} className="text-yellow-500" />
          <p className="text-xs font-bold">Upload Image</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/CashPOS")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/CashPOS")}
        >
          <FcBusinessman size={40} />
          <p className="text-xs font-bold">Cash POS</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/CreditPOS")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/CreditPOS")}
        >
          <FcBusinessman size={40} />
          <p className="text-xs font-bold">Credit POS</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/CreditNoteCash")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/CreditNoteCash")}
        >
          <FcBusinessman size={40} />
          <p className="text-xs font-bold">Cash Sale Credit Note</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/CreditNoteInvoice")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/CreditNoteInvoice")}
        >
          <FcBusinessman size={40} />
          <p className="text-xs font-bold">Credit Note Invoice</p>
        </div>

        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4   bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            alert("This Service will be available soon");
          }}
        >
          <img src={delivery} alt="logo" className="w-[40px] " />
          <p className="text-xs font-bold">Delivery</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/Receipt")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/Receipt")}
        >
          <MdReceiptLong size={40} />
          <p className="text-xs font-bold">Receipts</p>
        </div>
        <div
          className="relative rounded-md shadow-2xl ml-4 cursor-pointer text-center p-2  py-4 bg-slate-300 w-24 flex flex-col items-center"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <TbReportSearch size={40} />
          <p className="text-xs font-bold">Reports</p>
          {showDropdown && (
            <div className="w-[250px] fixed  mt-20 ml-35 left-[350px] lg:left-[580px] bg-white border border-gray-300 rounded-md shadow-lg z-50">
              <aside className="flex flex-col items-start w-[100%]">
                <div
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left"
                  onClick={() => {
                    navigate("/ZandXReport");
                  }}
                >
                  Z and X Report
                </div>
                <div
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left"
                  onClick={() => {
                    navigate("/SalesReport");
                  }}
                >
                  Sales Report
                </div>
                <div
                  onClick={() => {
                    navigate("/ReceiptReports");
                  }}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left"
                >
                  Receipt
                </div>
                {/* <div className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left">
                  Stock Movement Report
                </div>
                <div className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left">
                  Pickups Report
                </div> */}
              </aside>
            </div>
          )}
        </div>

        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/TablesSeating")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/TablesSeating")}
        >
          <GiRoundTable size={40} />
          <p className="text-xs font-bold">Tables</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4   bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            alert("This Service will be available soon");
          }}
        >
          <img src={waiter} alt="logo" className="w-[40px]" />
          <p className="text-xs font-bold">Waiters</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/PettyCash")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/PettyCash")}
        >
          <GiCash size={40} className="text-amber-950" />
          <p className="text-xs font-bold">Petty Cash</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4   bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/ListOfOrders");
          }}
        >
          <IoIosListBox size={50} className="text-gray-600" />
          <p className="text-xs font-bold">List Of Orders</p>
        </div>
        <div
          className={`rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4 ${
            isItemSelected("/HomePage")
              ? "bg-blue-500 text-white"
              : "bg-slate-300 hover:bg-slate-400 hover:text-white"
          } w-24 flex flex-col items-center`}
          onClick={() => handleItemClick("/HomePage")}
        >
          <img src={dashboard} alt="logo" className="w-[40px]" />
          <p className="text-xs font-bold">Dashboard</p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
