import { React, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";

const ZandXReport = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.changeTheState.user);
  const divisionState = useSelector((state) => state.changeDivision);
  const [seldivision, setseldivision] = useState(divisionState);
  const [alldata, setAllData] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [date, setDate] = useState(new Date().toISOString());
  const [vatregno, setvatregno] = useState("");
  const [pinno, setpinno] = useState("");
  const [orgname, setorgname] = useState("");
  const [adress1, setadress1] = useState("");
  const [adress2, setadress2] = useState("");
  const [adress3, setadress3] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [tillno, settillno] = useState("");
  const [Zflag, setZflag] = useState(false);
  const [listofdivisions, setlistofdivisions] = useState([]);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  useEffect(() => {
    getDivision();
    getCompanyDetails();
  }, []);

  const ZXReports = (value) => {
    axios
      .post(
        `${BASEURL}/ZXReports`,
        {
          date: moment(date.split("T")[0]).format("DD/MM/YYYY"),
          division: seldivision,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        settillno(response.data[0].TILLNO);
        if (value == "z") {
          generateZReport(response.data);
        } else if (value == "x") {
          generateXReport(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching List Of Tables Seating:", error);
      });
  };

  const AllZXReports = (value) => {
    axios
      .post(
        `${BASEURL}/AllZXReports`,
        {
          date: moment(date.split("T")[0]).format("DD/MM/YYYY"),
          division: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setAllData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching All Z X Reports:", error);
      });
  };

  const getDivision = () => {
    axios
      .get(`${BASEURL}/getDivisionsZX`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofdivisions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Divisions:", error);
      });
  };

  const getCompanyDetails = () => {
    axios
      .get(`${BASEURL}/getCompanyDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setvatregno(response.data[0].ORG_VAT_REG_NO);
        setpinno(response.data[0].ORG_PIN_NO);
        setorgname(response.data[0].ORG_NAME);
        setadress1(response.data[0].ORG_ADDRESS_1);
        setadress2(response.data[0].ORG_ADDRESS_2);
        setadress3(response.data[0].ORG_ADDRESS_3);
        setcity(response.data[0].ORG_CITY);
        setcountry(response.data[0].ORG_COUNTRY);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Cash Sale History:", error);
      });
  };

  // Z Report *************************************************************
  const generateZReport = (data) => {
    const doc = new jsPDF();
    let yPos = 10;
    doc.setFontSize(15);
    doc.text(orgname, 105, yPos, { align: "center" });
    yPos += 7;
    doc.setFontSize(11);
    doc.text("BRANCH : ", 90, yPos);
    doc.text(seldivision, 110, yPos);
    yPos += 7;
    doc.setFontSize(11);
    doc.text("Tel : 0710237671", 90, yPos);
    yPos += 7;
    doc.setFontSize(11);
    doc.text(adress1, 55, yPos);
    doc.text(",", 79, yPos);
    doc.text(adress2, 84, yPos);
    doc.text(",", 104, yPos);
    doc.text(adress3, 105, yPos);
    doc.text(",", 114, yPos);
    doc.text(city, 120, yPos);
    doc.text(",", 144, yPos);
    doc.text(country, 145, yPos);
    yPos += 7;
    doc.text("TILL NO : ", 90, yPos);
    doc.text(tillno ? tillno.toString() : "", 110, yPos);
    yPos += 7;
    doc.setFontSize(15);
    doc.text(
      ".............................................S U M M A R Y.............................................",
      20,
      yPos
    );
    doc.setFontSize(11);
    yPos += 7;
    doc.text("<A>", 20, yPos);
    doc.text("Total Sales", 40, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("<B>", 20, yPos);
    doc.text("Variance", 40, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("On date : ", 20, yPos);
    doc.text(moment(date.split("T")[0]).format("DD/MM/YYYY"), 40, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);
    yPos += 5;
    doc.text("SALES", 20, yPos);

    yPos += 7;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    // Headers for service name and amount
    doc.text("Cash Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Mpesa Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].CASHINMPESA ? data[0].CASHINMPESA.toString() : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Credit Card", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].CASHBYCARD ? data[0].CASHBYCARD.toString() : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Pesa Pal", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].CASHINPESAPAL ? data[0].CASHINPESAPAL.toString() : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Gift Vouchers", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Cap Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Invoice Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(data[0].AMOUNT ? data[0].AMOUNT.toString() : "0.00", 150, yPos);
    yPos += 7;
    doc.text("Smart Point Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Sales List Deposit", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Credit Notes", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Cheque Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Round Off", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Total Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0]
        ? (
            Number(data[0].CASHINMPESA) +
            Number(data[0].CASHBYCARD) +
            Number(data[0].CASHINPESAPAL) +
            Number(data[0].AMOUNT)
          ).toString()
        : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Less Discounts", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].DISCOUNT ? data[0].DISCOUNT.toString() : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Less Cash REFUNDS", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Less other REFUNDS", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Total NET SALES (A)", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0]
        ? (
            Number(data[0].CASHINMPESA) +
            Number(data[0].CASHBYCARD) +
            Number(data[0].CASHINPESAPAL) +
            Number(data[0].AMOUNT) -
            Number(data[0].DISCOUNT)
          ).toString()
        : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Total Float", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Deposits Cash", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Deposits Cheques", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("CAP Receipts Cash", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("CAP Receipts Cheq", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Gift Vouch Sold Cash", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Gift Vouch Sold Cheq", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Gift Vouch Sold S.F", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Gift Vouch Sold Card", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Debtors Asts Cash", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Total Tips", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);
    yPos += 7;
    doc.text("TOTAL COLLECTIONS (B)", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("TOTAL COLLECTION (A) + (B)", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0]
        ? (
            Number(data[0].CASHINMPESA) +
            Number(data[0].CASHBYCARD) +
            Number(data[0].CASHINPESAPAL) +
            Number(data[0].AMOUNT) -
            Number(data[0].DISCOUNT)
          ).toString()
        : "0.00",
      150,
      yPos
    );

    doc.save("Z_Report.pdf");
  };

  // X Report *************************************************************
  const generateXReport = (data) => {
    const doc = new jsPDF();
    let yPos = 10;
    doc.setFontSize(15);
    doc.text(orgname, 105, yPos, { align: "center" });
    yPos += 7;
    doc.setFontSize(11);
    doc.text("BRANCH : ", 90, yPos);
    doc.text(seldivision, 110, yPos);
    yPos += 7;
    doc.setFontSize(11);
    doc.text("Tel : 0710237671", 90, yPos);
    yPos += 7;
    doc.setFontSize(11);
    doc.text(adress1, 55, yPos);
    doc.text(",", 79, yPos);
    doc.text(adress2, 84, yPos);
    doc.text(",", 104, yPos);
    doc.text(adress3, 105, yPos);
    doc.text(",", 114, yPos);
    doc.text(city, 120, yPos);
    doc.text(",", 144, yPos);
    doc.text(country, 145, yPos);
    yPos += 7;
    doc.text("TILL NO : ", 90, yPos);
    doc.text(tillno ? tillno.toString() : "", 110, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    doc.setFontSize(11);
    yPos += 7;
    doc.text("On date : ", 20, yPos);
    doc.text(moment(date.split("T")[0]).format("DD/MM/YYYY"), 40, yPos);
    doc.text("Print Time : ", 20, yPos);
    doc.text(moment(date.split("T")[0]).format("DD/MM/YYYY"), 40, yPos);

    doc.text("X-report", 105, yPos, { align: "center" });

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);
    yPos += 5;

    yPos += 7;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    // Headers for service name and amount
    doc.text("Total Float", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Total Tips", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].CASHINMPESA ? data[0].CASHINMPESA.toString() : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Total Discount", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].CASHBYCARD ? data[0].CASHBYCARD.toString() : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Credit Card Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].CASHINPESAPAL ? data[0].CASHINPESAPAL.toString() : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Cash Sales", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Credit Card Details", 20, yPos);

    yPos += 7;
    doc.text("MPESA", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("ABSA", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(data[0].AMOUNT ? data[0].AMOUNT.toString() : "0.00", 150, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Less Petty Cash", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Less Pick ups", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Less Cash Refunds", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Less non cash Refunds", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Discount Details", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Total Discount", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0]
        ? (
            Number(data[0].CASHINMPESA) +
            Number(data[0].CASHBYCARD) +
            Number(data[0].CASHINPESAPAL) +
            Number(data[0].AMOUNT)
          ).toString()
        : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Complementary", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0].DISCOUNT ? data[0].DISCOUNT.toString() : "0.00",
      150,
      yPos
    );

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Transaction Wise sales", 50, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Seating", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);
    yPos += 7;
    doc.text("Take Away", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text(
      data[0]
        ? (
            Number(data[0].CASHINMPESA) +
            Number(data[0].CASHBYCARD) +
            Number(data[0].CASHINPESAPAL) +
            Number(data[0].AMOUNT) -
            Number(data[0].DISCOUNT)
          ).toString()
        : "0.00",
      150,
      yPos
    );
    yPos += 7;
    doc.text("Delivery", 20, yPos);
    doc.text(":", 90, yPos);
    doc.text("0.00", 150, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    yPos += 7;
    doc.text("Hourly Sales Analysis", 50, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);

    // Headers for service name and amount
    doc.text("Hour", 20, yPos);
    doc.text("Count", 65, yPos);
    doc.text("Avg Amt", 100, yPos);
    doc.text("Total Amt", 130, yPos);

    alldata.forEach((item, index) => {
      yPos += 5;
      const productNameWidth = 40;
      const wrappedProductName = doc.splitTextToSize(
        item.ONHOUR,
        productNameWidth
      );
      // Draw each column
      doc.text(wrappedProductName, 20, yPos);
      doc.text(item.COUNTTOTALS.toString(), 65, yPos);
      doc.text(item.AMOUNT.toString(), 100, yPos);
      doc.text(
        (Number(item.COUNTTOTALS) * Number(item.AMOUNT)).toString(),
        130,
        yPos
      );
      // Determine the maximum height among the columns for the current row
      const maxColumnHeight = Math.max(
        doc.getTextDimensions(wrappedProductName).h,
        doc.getTextDimensions(item.COUNTTOTALS.toString()).h,
        doc.getTextDimensions(item.AMOUNT.toString()).h,
        doc.getTextDimensions(
          (Number(item.COUNTTOTALS) * Number(item.AMOUNT)).toString()
        ).h
      );
      // Draw a dotted line after each item for separation
      yPos += maxColumnHeight + 5;
    });

    doc.save("X_Report.pdf");
  };

  return (
    <>
      <Navbar />
      {isloading ? (
        <Loading />
      ) : (
        <div className="h-auto w-full mt-4">
          <div className="m-3  font-semibold">
            <p className="font-xl">Z and X Reports </p>
          </div>
          <div className="flex flex-row mt-2 mx-3">
            <p className="w-[60px]">From:</p>
            <input
              type="date"
              className="w-34 h-6 border-black border ml-2 mr-2 rounded"
              value={date.split("T")[0]}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-row mt-2 mx-3 gap-2">
            <p>Division:</p>
            <Select
              styles={customStyless}
              onChange={(e) => {
                setseldivision(e.value);
                AllZXReports(e.value);
              }}
              value={
                seldivision
                  ? { label: seldivision, value: seldivision }
                  : { label: "", value: "" }
              }
              options={[
                ...listofdivisions.map((item) => ({
                  label: item.DIVISION,
                  value: item.DIVISION,
                })),
              ]}
            />
            <button
              className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3 py-1 mr-1  focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                if (!date) {
                  alert("Kindly Supply Date");
                } else if (!seldivision) {
                  alert("Kindly Supply Division");
                } else {
                  ZXReports("z");
                }
              }}
            >
              Z Report
            </button>
            <button
              className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3 py-1 mr-1  focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                if (!date) {
                  alert("Kindly Supply Date");
                } else if (!seldivision) {
                  alert("Kindly Supply Division");
                } else {
                  ZXReports("x");
                }
              }}
            >
              X Report
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ZandXReport;
