import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import OrderTakerNavbarAllPAges from "../../Components/OrderTakerNavbarAllPAges";
import Loading from "../../Components/Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";

const MySales = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.loginreducer);
  const date = new Date().toISOString();
  const userPass = state?.user?.userCode;

  const [PassOrderNO, setPassOrderNO] = useState("");
  const [search, setSearch] = useState("");
  const [OrderList, setOrderList] = useState([]);
  const [seldivision, setSelDivision] = useState("");
  const userState = useSelector((state) => state?.getUserState);
  const userLoggeduserCode = userState?.user?.userCode;

  const [isloading, setisloading] = useState(false);

  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  useEffect(() => {
    if (userLoggeduserCode) {
      FetchDetails();
    }
  }, [userLoggeduserCode]);
  function FetchDetails() {
    setisloading(true);
    axios
      //   .get(`${BASEURL}/getTechLogSheetData)`, {
      .get(`${BASEURL}/getFlyingHours?PILOT CODE=${userLoggeduserCode}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setOrderList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getOrders List", err);
      });
  }

  const filteredData = OrderList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const sDate = new Date(item["TECHLOG DATE"]);
    const eDate = new Date(item["TECHLOG DATE"]);

    const textMatch = searchData.every((term) => {
      return ["FLYINGHRS", "TECHLOG DATE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    return textMatch && dateMatch;
  });

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <OrderTakerNavbarAllPAges />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu || "My Sales"}</h1>
      </header>
      <section className="lg:flex items-center gap-5 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">Order taker</p>
          <Select className="w-[300px] z-[200]" />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">From Date</p>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">End Date</p>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
      </section>
      {isloading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table text-sm overflow__y__ relative ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td className=" pr-5 text-right">
                  <p>ORDER N0</p>
                </td>
                <td className=""> ORDER TYPE</td>
                <td className=""> ORDER TAKER</td>
                <td className=""> DATE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <td className=" pr-5 text-right">
                      {item["FLYINGHRS"] ? item["FLYINGHRS"] : "NA"}
                    </td>

                    <td className="">
                      {item["TECHLOG DATE"]
                        ? item["TECHLOG DATE"].slice(8, 10) +
                          "/" +
                          item["TECHLOG DATE"].slice(5, 7) +
                          "/" +
                          item["TECHLOG DATE"].slice(0, 4)
                        : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MySales;
