import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
function UploadCategoryImage() {
  const navigate = useNavigate();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isloading, setisloading] = useState(true);
  const [category, setcategory] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const getAllCategories = () => {
    axios
      .get(`${BASEURL}/getallcategories`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setcategory(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Main Categories:", error);
      });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  async function handleSubmit() {
    if (selectedFile && selectedCategory) {
      try {
        const formData = new FormData();
        formData.append("imageValue", selectedFile);
        formData.append("CATEGORYCODE", selectedCategory);
        const response = await axios.post(
          `${BASEURL}/insertcategoryImage`,
          formData,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        alert(response.data.message);
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert("Select category and image");
    }
  }

  useEffect(getAllCategories, []);
  return (
    <>
      <Navbar />
      {isloading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-center justify-center h-screen">
            <div className="bg-gray-200 p-8 rounded shadow-lg">
              <div className="flex flex-col">
                <div className="flex flex-row mt-4">
                  <p className="mr-2">Select Category :</p>
                  <Select
                    onChange={(e) => {
                      setSelectedCategory(e.value);
                    }}
                    styles={customStyless}
                    options={category.map((item) => ({
                      label: item.MAINCATEGORY,
                      value: item.MAINCATEGORY,
                    }))}
                  />
                </div>
                <div className="flex flex-row mt-2">
                  <p>Select Image :</p>
                  <input
                    className="ml-6"
                    type="file"
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="flex flex-row mt-4">
                  <button
                    onClick={handleSubmit}
                    className="ml-32 p-2 bg-blue-500 text-white rounded"
                  >
                    Submit
                  </button>
                  {/* <button
                    className="mx-2 p-2 bg-blue-500 text-white rounded"
                    onClick={() => {
                      navigate("/Inventory");
                    }}
                  >
                    
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UploadCategoryImage;
