const initialState = { poHeader: {}, poDetails: [] };

const poToGrnReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POTOGRN":
      return action.data;
    case "RESET_POTOGRN":
      return initialState;
    default:
      return state;
  }
};

export default poToGrnReducer;
