import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading";

function ListofOrdersNotStarted({ division }) {
  const [isloading, setisloading] = useState(false);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [listOfOrders, setlistOfOrders] = useState([]);
  const userState = useSelector((state) => state.changeTheState.user);
  const [search, setSearch] = useState("");
  const [popup, setPopUp] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [popupLoad, setPopUpLoad] = useState(false);
  const divisionState = useSelector((state) => state.changeDivision);

  // Filter
  const filteredData = itemsList?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    // const sDate = new Date(item["ENQUIRYDATE"]);
    // const eDate = new Date(item["ENQUIRYDATE"]);

    //  const dateMatch =
    //    (!startdate || sDate >= new Date(startdate)) &&
    //    (!enddate || eDate <= new Date(enddate));

    const textMatch = searchData.every((term) => {
      return ["ITEMCODE", "ITEMDESCRIPTION", "ITEMQTY", "PROPERTY"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    // const clientMatch =
    //   !selectedClientName ||
    //   item["CLIENTNAME"]?.toLowerCase() === selectedClientName?.toLowerCase();

    return textMatch;
  });

  useEffect(() => {
    if (division || divisionState) {
      getOrderStatus();
    }
  }, [division, divisionState]);

  const getOrderStatus = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/getOrderStatus?DIVISION=${divisionState}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);

        const filteredOrders = response.data.filter(
          (order) =>
            order.PICKEDUP !== true &&
            (order.PREPERATIONSTATUS === null || order.PREPERATIONSTATUS === 0)
        );

        setlistOfOrders(filteredOrders);
        console.log(filteredOrders);
        setisloading(false);
      })

      .catch((error) => {
        console.error(
          "Error fetching List Of Project Idss /getOrderStatus:",
          error
        );
      });
  };

  const getOrderItems = (value) => {
    setPopUpLoad(true);
    axios
      .get(`${BASEURL}/getOrderItems?ENQUIRYNO=${value}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setItemsList(response.data);
        setPopUpLoad(false);
      })
      .catch((error) => {
        setPopUpLoad(false);
        console.error("Error fetching List Of Items /getOrderItems:", error);
      });
  };

  // ******************* Save ********************************
  async function UpdateStatus(orderNo) {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/orderStatusUpdate`,
        {
          ORDERNO: Number(orderNo),
          STATUSTYPE: "STARTED",
          STATUSUPDATEDBY: userState?.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert("Status Updated Successfully");
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.error(`Error while saving Status /orderStatusUpdate`, error);
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      {/* <KitchenAllPagesNavbar /> */}
      <header className="header__of__main lg:flex justify-between items-end p-2">
        <h1 className="header__of__page">List Of Orders Not Started</h1>
      </header>

      {/* Table Details */}
      <div className="overflow-y-auto overflow-x-hidden h-80 shadow-lg">
        <div className="table__pos__app lg:w-[550px]">
          <table className="custom-table">
            <thead className="header-cell">
              <tr className="">
                <td className="py-1 px-4 rounded-tl-lg border-none">
                  ORDER NO
                </td>
                <td className="py-1 px-4">ORDER DATE</td>
                <td className="py-1 px-4">CUSTOMER NAME</td>
              </tr>
            </thead>
            <tbody>
              {listOfOrders.map((item, index) => (
                <tr
                  key={item.ENQUIRYNO}
                  className={
                    index < listOfOrders.length
                      ? "border-b cursor-pointer table-row"
                      : ""
                  }
                  onClick={() => {
                    setSelectedOrder(item);
                    setPopUp(true);
                    getOrderItems(item.ENQUIRYNO);
                  }}
                >
                  <td className="text-right">{item.ENQUIRYNO}</td>
                  <td className="text-right">
                    {item.ENQUIRYDATE.slice(8, 10) +
                      "/" +
                      item.ENQUIRYDATE.slice(5, 7) +
                      "/" +
                      item.ENQUIRYDATE.slice(0, 4)}
                  </td>
                  <td>{item["CLIENT NAME"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* PopUp */}
      {popup && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] lg:w-[900px] p-1 overflow-y-auto w-[95%]">
          <div className="lg:flex flex-row mt-2">
            {selectedOrder && (
              <div className="p-4 bg-gray-100 rounded-md w-full">
                <h2 className="text-2xl font-extrabold text-center mb-4">
                  Selected Order Details
                </h2>
                {/* Image Details  */}
                {/* <div className="flex justify-center items-center">
                  {selectedItem.IMAGENAME?.data ? (
                    <>
                      {" "}
                      <img
                        className="text-amber-950"
                        src={URL.createObjectURL(
                          new Blob(
                            [new Uint8Array(selectedItem.CATEGORYIMAGE?.data)],
                            {
                              type: "image/jpeg",
                            }
                          )
                        )}
                      />
                    </>
                  ) : (
                    <IoFastFoodOutline size={50} className="text-amber-950" />
                  )}
                </div> */}

                <div className="grid grid-cols-2 gap-4 mt-2">
                  <div>
                    <strong>ORDER NO:</strong> {selectedOrder.ENQUIRYNO || ""}
                  </div>
                  <div>
                    <strong>ORDER DATE:</strong>{" "}
                    {selectedOrder.ENQUIRYDATE
                      ? moment(selectedOrder.ENQUIRYDATE).format("DD/MM/YYYY")
                      : ""}
                  </div>
                  <div>
                    <strong>CUSTOMER NAME:</strong>{" "}
                    {selectedOrder["CLIENT NAME"] || ""}
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            className="absolute top-4 right-20 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            onClick={async () => {
              await UpdateStatus(selectedOrder.ENQUIRYNO);
              window.location.reload();
            }}
          >
            START
          </button>
          <button
            className="absolute top-4 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            onClick={() => setPopUp(false)}
          >
            Close
          </button>

          {popupLoad ? (
            <Loading />
          ) : (
            <aside className="table__pos__app text-sm ">
              <div className="lg:flex flex-row mt-2">
                <p className="text-red-600 ml-2">Search:</p>
                <input
                  className="w-64 h-6 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <table class="custom-table">
                <thead class="header-cell">
                  <tr>
                    <td className="text-left">ITEM CODE</td>
                    <td className="text-left">ITEM NAME</td>
                    <td className="text-left">QUANTITY</td>
                    <td className="text-left">SPICE LEVEL</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((row, index) => (
                    <tr
                      key={index}
                      className={
                        index < itemsList.length
                          ? "border-b cursor-pointer text-sm table-row"
                          : ""
                      }
                    >
                      <td className="text-left">
                        {row.ITEMCODE ? row.ITEMCODE : ""}
                      </td>
                      <td className="text-left">
                        {row.ITEMDESCRIPTION ? row.ITEMDESCRIPTION : ""}
                      </td>
                      <td className="text-right">
                        {row.ITEMQTY ? row.ITEMQTY : ""}
                      </td>
                      <td className="text-left">
                        {row.PROPERTY ? row.PROPERTY : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          )}
        </div>
      )}
    </div>
  );
}

export default ListofOrdersNotStarted;
