import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./context/ProtectedRoute";
import MainLogin from "./Pages/MainLogin";
import Login from "./Pages/Login";
import HomePage from "./Pages/HomePage";
import Inventory from "./Pages/Inventory";
import CashPOS from "./Pages/CashPOS";
import CreditPOS from "./Pages/CreditPOS";
import Receipt from "./Pages/Receipt";
import TablesSeating from "./Pages/TablesSeating";
import PettyCash from "./Pages/PettyCash";
import ZandXReport from "./Pages/ZandXReport";
import ReceiptReports from "./Pages/ReceiptReplorts";
import SalesReport from "./Pages/SalesReport";
import UploadCategoryImage from "./Pages/UploadCategoryImage";
import CreditNoteCash from "./Pages/CreditNoteCash";
import CreditNoteInvoice from "./Pages/CreditNoteInvoice";
import TableOrders from "./Pages/TableOrders";
import POProcessing from "./Pages/POProcessing";
import ItemCreation from "./Pages/ItemCreation";
import LocationCreation from "./Pages/LocationCreation";
import SupplierCreation from "./Pages/SupplierCreation";
import Grn from "./Pages/Grn";
import CustomerMaster from "./Pages/CustomerMaster";
import ORDERTAKERDASHBOARD from "./Pages/ORDERTAKER/ORDERTAKERDASHBOARD";
import TakeOrder from "./Pages/ORDERTAKER/TakeOrder";
import MyListofOrders from "./Pages/ORDERTAKER/MyListofOrders";
import MySales from "./Pages/ORDERTAKER/MySales";
import ListOfOrders from "./Pages/ListOfOrders";

// kitchen
import KitchenDashboard from "./Pages/Kitchen/KitchenDashboard";
import ListofOrdersNotStarted from "./Pages/Kitchen/ListofOrdersNotStarted";
import ListofOrdersKitchen from "./Pages/Kitchen/ListofOrdersKitchen";
import ListofOrdersReady from "./Pages/Dashboard/ListofOrdersReady";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/" element={<MainLogin />} />
          <Route
            path="/HomePage"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Inventory"
            element={
              <ProtectedRoute>
                <Inventory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ListOfOrders"
            element={
              <ProtectedRoute>
                <ListOfOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CashPOS"
            element={
              <ProtectedRoute>
                <CashPOS />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CreditPOS"
            element={
              <ProtectedRoute>
                <CreditPOS />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Receipt"
            element={
              <ProtectedRoute>
                <Receipt />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TablesSeating"
            element={
              <ProtectedRoute>
                <TablesSeating />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PettyCash"
            element={
              <ProtectedRoute>
                <PettyCash />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ZandXReport"
            element={
              <ProtectedRoute>
                <ZandXReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ReceiptReports"
            element={
              <ProtectedRoute>
                <ReceiptReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SalesReport"
            element={
              <ProtectedRoute>
                <SalesReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CreditNoteCash"
            element={
              <ProtectedRoute>
                <CreditNoteCash />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CreditNoteInvoice"
            element={
              <ProtectedRoute>
                <CreditNoteInvoice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TableOrders"
            element={
              <ProtectedRoute>
                <TableOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UploadCategoryImage"
            element={
              <ProtectedRoute>
                <UploadCategoryImage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/POProcessing"
            element={
              <ProtectedRoute>
                <POProcessing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ItemCreation"
            element={
              <ProtectedRoute>
                <ItemCreation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/LocationCreation"
            element={
              <ProtectedRoute>
                <LocationCreation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SupplierCreation"
            element={
              <ProtectedRoute>
                <SupplierCreation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/GrnProcessing"
            element={
              <ProtectedRoute>
                <Grn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CustomerMaster"
            element={
              <ProtectedRoute>
                <CustomerMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ORDERTAKERDASHBOARD"
            element={
              <ProtectedRoute>
                <ORDERTAKERDASHBOARD />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TakeOrder"
            element={
              <ProtectedRoute>
                <TakeOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MyListofOrders"
            element={
              <ProtectedRoute>
                <MyListofOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MySales"
            element={
              <ProtectedRoute>
                <MySales />
              </ProtectedRoute>
            }
          />

          {/* kitchen */}
          <Route
            path="/KitchenDashboard"
            element={
              <ProtectedRoute>
                <KitchenDashboard />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/ListofOrdersNotStarted"
            element={
              <ProtectedRoute>
                <ListofOrdersNotStarted />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ListofOrdersKitchen"
            element={
              <ProtectedRoute>
                <ListofOrdersKitchen />
              </ProtectedRoute>
            }
          /> */}
          {/* Dashboard  */}
          <Route
            path="/ListofOrdersReady"
            element={
              <ProtectedRoute>
                <ListofOrdersReady />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
