import React, { useEffect, useState } from "react";
import { FaBars, FaTimes, FaHome } from "react-icons/fa";
import { FiHome } from "react-icons/fi";
import "../CSS/Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
// import { logIn } from "../../Redux/Action";

const OrderTakerNavbarAllPAges = () => {
  const navigate = useNavigate();
  const [showreports, setshowreports] = useState(false);
  const [showreports2, setshowreports2] = useState(false);
  const [showMaster, setShowMaster] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const state = useSelector((state) => state.changeTheState.user);
  const divisionState = useSelector((state) => state.changeDivision);
  const userName = state?.emailId?.split("@")[0].toUpperCase();
  const BASEURL = process.env.REACT_APP_BASEURL;
  //   const authToken = window.sessionStorage.getItem("auth-token");

  function logOut() {
    // dispatch(logIn([]));
    navigate("/");
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="centernav">
      <div className="navbar-container uppercase">
        {/* <nav className="navbar">
          <div className="">
            <div className="fabar" onClick={toggleMenu}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
            <div className="hover-dropdown">
              <ul className="ul__list__style__scanner">
                <div>
                  <p
                    onClick={() => {
                      navigate("/MyListofOrders");
                    }}
                  >
                    My List of Orders
                  </p>
                  <p
                    onClick={() => {
                      navigate("/MySales");
                    }}
                  >
                    My Sales
                  </p>
                  <p
                    onClick={() => {
                      navigate("/TakeOrder");
                    }}
                  >
                    Take Order{" "}
                  </p>
                </div>
              </ul>
            </div>
          </ul>
        </nav> */}
        <div className="logedIn__as__btn">
          <h2>Welcome to MarsTrack POS ORDER TAKER</h2>
          <h3>Division : {divisionState ? divisionState : ""}</h3>
          <h3 className="hidden lg:block">
            {" "}
            Logged in as : {userName ? userName : "N/A"}{" "}
          </h3>
          {/* {state.emailId.split("@")[0].toUpperCase()}</h3> */}
        </div>

        <aside>
          <button
            onClick={() => {
              navigate("/ORDERTAKERDASHBOARD");
            }}
            className="button__logout__navbar"
          >
            DASHBOARD
          </button>
        </aside>
        <div className="logout__fullscren">
          <button
            onClick={() => {
              navigate("/ORDERTAKERDASHBOARD");
            }}
            className="log__out__icon"
          >
            {" "}
            <FaHome />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default OrderTakerNavbarAllPAges;
