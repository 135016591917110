import changeTheState from "./loginLogout";
import changeDivision from "./divisionReducer";
import changeLoginState from "./loginstatred";
import selectStatements from "./statements";
import poToGrnReducer from "./poToGrnReducer";
import { combineReducers } from "redux";

const rootReducers = combineReducers({
  changeTheState,
  changeLoginState,
  selectStatements,
  changeDivision,
  poToGrnReducer,
});

export default rootReducers;
