import { React, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";

const SalesReport = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [data, setData] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [listofpaymentmodes, setlistofpaymentmodes] = useState([]);
  const [selpaymentmode, setselpaymentmode] = useState("");
  const [listofdivisions, setlistofdivisions] = useState([]);
  const [seldivision, setseldivision] = useState("");
  const [listoftillno, setlistoftillno] = useState([]);
  const [seltillno, setseltillno] = useState("");
  const [unpaiddata, setunpaiddata] = useState([]);
  const [showunpaid, setShowunpaid] = useState(false);
  const [showpaid, setshowpaid] = useState(true);
  const divisionState = useSelector((state) => state.changeDivision);
  const [totalVat, setTotalVat] = useState(0);
  const [totalLevy, setTotalLevy] = useState(0);
  const [totalExcl, setTotalExcl] = useState(0);
  const [totalIncl, setTotalIncl] = useState(0);
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );

  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  const filtCash = data.filter((item) => {
    const sDate = item.INVDATE;
    const eDate = item.INVDATE;

    if (
      !startdate &&
      !enddate &&
      !selpaymentmode &&
      !seldivision &&
      !seltillno
    ) {
      return true;
    }

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    // Payment mode filter
    const paymentModeMatch =
      !selpaymentmode || item["PAYMENT MODE"] === selpaymentmode;

    // Division filter
    const divisionMatch =
      !seldivision || item["CREATED DIVISON CODE"] === seldivision;

    // Till number filter
    const tillNoMatch = !seltillno || item.TILLNO === seltillno;

    return dateMatch && paymentModeMatch && divisionMatch && tillNoMatch;
  });

  const filtUnpaid = unpaiddata.filter((item) => {
    const sDate = item.INVDATE;
    const eDate = item.INVDATE;

    if (
      !startdate &&
      !enddate &&
      !selpaymentmode &&
      !seldivision &&
      !seltillno
    ) {
      return true;
    }

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    // Payment mode filter
    const paymentModeMatch = !selpaymentmode || item.PAYMODE === selpaymentmode;

    // Division filter
    const divisionMatch = !seldivision || item.DIVISION === seldivision;

    // Till number filter
    const tillNoMatch = !seltillno || item.TILLNO === seltillno;

    return dateMatch && paymentModeMatch && divisionMatch && tillNoMatch;
  });

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  useEffect(() => {
    let vatTotal = 0;
    let levyTotal = 0;
    let exclTotal = 0;
    let inclTotal = 0;

    if (showpaid) {
      filtCash.forEach((item) => {
        vatTotal += parseFloat(item["VAT AMT"]);
        levyTotal += parseFloat(item["LEVY AMT"]);
        exclTotal += parseFloat(item["AMOUNT EXCLUSIVE VAT"]);
        inclTotal += parseFloat(item["AMOUNT INCLUSIVE VAT"]);
      });

      setTotalVat(vatTotal);
      setTotalLevy(levyTotal);
      setTotalExcl(exclTotal);
      setTotalIncl(inclTotal);
    }
  }, [filtCash, showpaid]);

  useEffect(() => {
    let vatTotal = 0;
    let levyTotal = 0;
    let exclTotal = 0;
    let inclTotal = 0;

    if (showunpaid) {
      filtUnpaid.forEach((item) => {
        vatTotal += parseFloat(item.VATAMOUNT);
        levyTotal += parseFloat(item.LEVYAMOUNT);
        exclTotal += parseFloat(item.SALESAMOUNT);
        inclTotal += parseFloat(item.RECEIPTAMT);
      });

      setTotalVat(vatTotal);
      setTotalLevy(levyTotal);
      setTotalExcl(exclTotal);
      setTotalIncl(inclTotal);
    }
  }, [filtUnpaid, showunpaid]);

  useEffect(() => {
    getUnpaid();
    getCashSaleHistory();
    getPaymentModeDetails();
    getDivisions();
    getTillNo();
  }, []);

  const ExcelPaid = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sales Report Paid");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 50, 25, 25, 25, 15];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "INV NO",
      "INV DATE",
      "SALESMAN",
      "DIVISION",
      "PAYMENT MODE",
      "LEVY AMOUNT",
      "VAT AMOUNT",
      "AMOUNT EXCLUSIVE VAT",
      "AMOUNT INCLUSIVE VAT",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Sales_Report_Paid.xlsx";
      link.click();
    });
  };

  const ExcelUnPaid = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sales Report Paid");
    const rows = document.querySelectorAll(".table_2 tbody tr");

    const columnWidths = [15, 50, 25, 25, 25, 15];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "CASH SALE NO",
      "INV DATE",
      "SALES MAN",
      "DIVISON",
      "PAYMENT MODE",
      "LEVY AMOUNT",
      "VAT AMOUNT",
      "AMOUNT EXCLUSIVE VAT",
      "AMOUNT INCLUSIVE VAT",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Sales_Report_Paid.xlsx";
      link.click();
    });
  };

  const PdfPaid = () => {
    const doc = new jsPDF();

    doc.text("Sales Report Paid", 10, 10);

    const columns = [
      "INVNO",
      "INVDATE",
      "SALESMAN",
      "DIVISION",
      "PAYMENT MODE",
      "LEVY AMOUNT",
      "VAT AMOUNT",
      "AMOUNT EXCLUSIVE VAT",
      "AMOUNT INCLUSIVE VAT",
    ];
    const data = filtCash.map((stock) => [
      stock.INVNO,
      moment(stock.INVDATE).format("DD/MM/YYYY"),
      stock.SALESMAN,
      stock["CREATED DIVISON CODE"],
      stock["PAYMENT MODE"],
      stock["LEVY AMT"],
      stock["VAT AMT"],
      stock["AMOUNT EXCLUSIVE VAT"],
      stock["AMOUNT INCLUSIVE VAT"],
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Sales_Report_Paid.pdf");
  };

  const PdfUnPaid = () => {
    const doc = new jsPDF();

    doc.text("Sales Report UnPaid", 10, 10);

    const columns = [
      "CASH SALE NO",
      "INV DATE",
      "SALES MAN",
      "DIVISON",
      "PAYMENT MODE",
      "LEVY AMOUNT",
      "VAT AMOUNT",
      "AMOUNT EXCLUSIVE VAT",
      "AMOUNT INCLUSIVE VAT",
    ];

    const data = filtUnpaid.map((stock) => [
      stock.CASHSALENO,
      moment(stock.INVDATE).format("DD/MM/YYYY"),
      stock.SALESMAN,
      stock.DIVISION,
      stock.PAYMODE,
      stock.LEVYAMOUNT,
      stock.VATAMOUNT,
      stock.SALESAMOUNT,
      stock.RECEIPTAMT,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Sales_Report_UnPaid.pdf");
  };

  const getPaymentModeDetails = () => {
    axios
      .get(`${BASEURL}/getPaymentModes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofpaymentmodes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Receipts Data:", error);
      });
  };

  const getDivisions = () => {
    axios
      .get(`${BASEURL}/getDivisions`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofdivisions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Divisions:", error);
      });
  };

  const getTillNo = () => {
    axios
      .post(
        `${BASEURL}/getTillNo`,
        {
          DIVISON: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistoftillno(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Divisions:", error);
      });
  };

  const getCashSaleHistory = () => {
    axios
      .post(
        `${BASEURL}/getCashSaleHistory`,
        {
          DIVISON: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Cash Sale History:", error);
        setisloading(false);
      });
  };

  const getUnpaid = () => {
    axios
      .post(
        `${BASEURL}/getUnpaid`,
        {
          division: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setunpaiddata(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Cash Sale History:", error);
      });
  };

  return (
    <>
      <Navbar />
      {isloading ? (
        <Loading />
      ) : (
        <div className="h-auto w-full mt-4">
          <div className="m-3  font-semibold">
            <p className="font-xl">My Sales for the day</p>
          </div>

          {/* First Row */}
          <div className="flex flex-row mt-2 mx-3 gap-2">
            <p>From :</p>
            <input
              type="date"
              className="w-34 h-6 border-black border ml-2 mr-2 rounded"
              value={startdate.split("T")[0]}
              onChange={(e) => {
                setstartdate(e.target.value);
              }}
            ></input>
            <p>To :</p>
            <input
              type="date"
              className="w-34 h-6 border-black border ml-2 mr-2 rounded"
              value={enddate.split("T")[0]}
              onChange={(e) => {
                setEnddate(e.target.value);
              }}
            ></input>
            <p>Payment Mode :</p>
            <Select
              styles={customStyless}
              onChange={(e) => {
                setselpaymentmode(e.value);
              }}
              value={
                selpaymentmode
                  ? { label: selpaymentmode, value: selpaymentmode }
                  : { label: "", value: "" }
              }
              options={[
                { label: "", value: "" }, // Empty option
                ...listofpaymentmodes.map((item) => ({
                  label: item.PAYMODE,
                  value: item.PAYMODE,
                })),
              ]}
            />
          </div>

          {/* Second Row */}
          <div className="flex flex-row mt-2 mx-3 gap-2">
            &nbsp;
            <p> Till Number :</p>
            &nbsp;
            <Select
              styles={customStyless}
              onChange={(e) => {
                setseltillno(e.value);
              }}
              value={
                seltillno
                  ? { label: seltillno, value: seltillno }
                  : { label: "", value: "" }
              }
              options={[
                { label: "", value: "" }, // Empty option
                ...listoftillno.map((item) => ({
                  label: item.TILLNO,
                  value: item.TILLNO,
                })),
              ]}
            />
            <div>
              <RiFileExcel2Line
                onClick={() => {
                  if (showpaid == true) {
                    ExcelPaid();
                  } else {
                    ExcelUnPaid();
                  }
                }}
                size={35}
              />
            </div>
            <div>
              {" "}
              <BsFileEarmarkPdf
                onClick={() => {
                  if (showpaid == true) {
                    PdfPaid();
                  } else {
                    PdfUnPaid();
                  }
                }}
                size={35}
              />
            </div>
          </div>

          <div className="flex flex-row justify-end">
            <button
              className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3 py-1 mr-1  focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                setShowunpaid(true);
                setshowpaid(false);
              }}
            >
              View Unpaid
            </button>
            <button
              className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3 py-1 mr-1  focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                setShowunpaid(false);
                setshowpaid(true);
              }}
            >
              View
            </button>
          </div>

          <div className="w-full h-[350px] p-1 overflow-y-auto">
            {showpaid && (
              <aside className="table__pos__app ">
                <table class="custom-table text-sm table_1">
                  <thead class="header-cell">
                    <tr>
                      <td className="text-right">CASH SALE NO</td>
                      <td className="text-left">INV DATE</td>
                      <td className="text-left">SALES MAN</td>
                      <td className="text-left">DIVISON</td>
                      <td className="text-left">PAYMENT MODE</td>
                      <td className="text-right">LEVY AMOUNT</td>
                      <td className="text-right">VAT AMOUNT</td>
                      <td className="text-right">AMOUNT EXCLUSIVE VAT</td>
                      <td className="text-right">AMOUNT INCLUSIVE VAT</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtCash.map((value, index) => (
                      <tr
                        className={
                          index < data.length
                            ? "border-b cursor-pointer text-sm table-row"
                            : ""
                        }
                      >
                        <td className="text-right">
                          {value.INVNO ? value.INVNO : ""}
                        </td>
                        <td className="text-left">
                          {value.INVDATE
                            ? moment(value.INVDATE).format("DD/MM/YYYY")
                            : ""}
                        </td>
                        <td className="text-left">
                          {value.SALESMAN ? value.SALESMAN : ""}
                        </td>
                        <td className="text-left">
                          {value["CREATED DIVISON CODE"]
                            ? value["CREATED DIVISON CODE"]
                            : ""}
                        </td>
                        <td className="text-left">
                          {value["PAYMENT MODE"] ? value["PAYMENT MODE"] : ""}
                        </td>
                        <td className="text-right">
                          {value["LEVY AMT"]
                            ? value["LEVY AMT"].toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "N/A"}
                        </td>
                        <td className="text-right">
                          {value["VAT AMT"]
                            ? value["VAT AMT"].toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "N/A"}
                        </td>
                        <td className="text-right">
                          {value["AMOUNT EXCLUSIVE VAT"]
                            ? value["AMOUNT EXCLUSIVE VAT"].toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "N/A"}
                        </td>
                        <td className="text-right">
                          {value["AMOUNT INCLUSIVE VAT"]
                            ? value["AMOUNT INCLUSIVE VAT"].toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </aside>
            )}
            {showunpaid && (
              <table className=".table_2 border-collapse rounded uppercase w-full border border-solid border-gray-700 border-t-1.5">
                <thead className="border border-solid border-gray-700 border-t-1.5 bg-blue-900 border-t-2 border-blue-800 rounded-md text-white font-semibold h-8 sticky top-0 w-98 z-0">
                  <tr className="bg-blue-900 text-white uppercase text-sm">
                    <td className="text-right">CASH SALE NO</td>
                    <td className="text-left">INV DATE</td>
                    <td className="text-left">SALES MAN</td>
                    <td className="text-left">DIVISON</td>
                    <td className="text-left">PAYMENT MODE</td>
                    <td className="text-right">LEVY AMOUNT</td>
                    <td className="text-right">VAT AMOUNT</td>
                    <td className="text-right">AMOUNT EXCLUSIVE VAT</td>
                    <td className="text-right">AMOUNT INCLUSIVE VAT</td>
                  </tr>
                </thead>
                <tbody>
                  {filtUnpaid.map((value, index) => (
                    <tr
                      className={
                        index < unpaiddata.length
                          ? "border-b cursor-pointer text-sm"
                          : ""
                      }
                    >
                      <td className="text-right">
                        {value.CASHSALENO ? value.CASHSALENO : ""}
                      </td>
                      <td className="text-left">
                        {value.INVDATE
                          ? moment(value.INVDATE).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td className="text-left">
                        {value.SALESMAN ? value.SALESMAN : ""}
                      </td>
                      <td className="text-left">
                        {value.DIVISION ? value.DIVISION : ""}
                      </td>
                      <td className="text-left">
                        {value.PAYMODE ? value.PAYMODE : ""}
                      </td>
                      <td className="text-right">
                        {value.LEVYAMOUNT
                          ? value.LEVYAMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {value.VATAMOUNT
                          ? value.VATAMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {value.SALESAMOUNT
                          ? value.SALESAMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {value.RECEIPTAMT
                          ? value.RECEIPTAMT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div className="flex flex-row mt-2 ml-2">
            <p className="ml-20">Total Vat:</p>
            <input
              className="w-48 h-6 border-black border"
              value={
                totalVat
                  ? totalVat.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
            ></input>
            <p className="ml-20">Total Levy:</p>
            <input
              className="w-48 h-6 border-black border"
              value={
                totalLevy
                  ? totalLevy.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
            ></input>
          </div>
          <div className="flex flex-row mt-2 ml-2">
            <p>Total Sales Exc Taxes:</p>
            <input
              className="w-48 h-6 border-black border"
              value={
                totalExcl
                  ? totalExcl.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
            ></input>
            <p className="ml-2">Total Sales Inc Taxes:</p>
            <input
              className="w-48 h-6 border-black border"
              value={
                totalIncl
                  ? totalIncl.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
            ></input>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesReport;
